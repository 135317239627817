import React, { useEffect, useState } from 'react'
import VMTable from './VMTable'
import '../../style/Views.scss'
import Grid from '@mui/material/Grid'
import LeftPanel from './../Support/PageLeftPanel'
import { ReactComponent as VMIcon } from '../../assets/images/VM_Icon.svg'
import { useHistory } from 'react-router-dom'
import { isStrategySelected } from '../../utilities/helpers'
import store from '../../redux/store'
// import _ from 'underscore'
import { useSelector } from 'react-redux'

const buttonToCategoryMapping = {
    0: 'Unmet Need',
    1: 'Clinical',
    2: 'Economic',
    3: 'Humanistic',
}

export default function ValueMessages(props) {
    let storeVMCategory = useSelector((state) => state.valueMessageFilterMapping)
    const history = useHistory()
    const [formData, setFormData] = useState({})

    // Columns declared as const weren't loading Store in time for render
    // eslint-disable-next-line no-unused-vars
    const [filterCategory, setFilterCategory] = useState(
        storeVMCategory ? buttonToCategoryMapping[storeVMCategory] : buttonToCategoryMapping[0],
    )

    function createNew(incomingData) {
        setFormData(incomingData)
    }

    function filter(category) {
        setFilterCategory(category)
    }

    // if no strategy selected, navigate to strategy selection page
    useEffect(() => {
        if (!isStrategySelected()) {
            history.push('/roadmaps')
        }
    })

    let vmDisplayName =
        (store.getState().settings?.displayNames?.valueMessages &&
            store.getState().settings?.displayNames?.valueMessages) ||
        'Loading'

    return (
        <Grid container item spacing={2} direction='column' sx={{ p: 2, mb: 2 }}>
            <LeftPanel imgsrc={VMIcon} text={vmDisplayName} id='vm' createNew={createNew} filter={filter}/>
            <VMTable newFormData={formData} filterCategory={buttonToCategoryMapping[storeVMCategory]}
                     autoOpenState={props.location.autoOpenData} />
        </Grid>
    )
}
