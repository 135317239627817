import { Badge, ClickAwayListener, Popper, SvgIcon } from '@mui/material'
import React from 'react'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as CommentsIcon } from '../../../../../assets/images/messages-bubble-add.svg'
import { Comments } from '../../Comments'
import { CommentsProps } from '../../Comments.types'
import { Fade } from '@mui/material/'
import Box from '@mui/material/Box'
import { useCommentsQueries } from '../../useCommentsQueries'
import { useIsMobileDevice } from '../../../../hooks/useIsMobileDevice'

const POPOVER_MODIFIERS = [
    {
        name: 'flip',
        enabled: true,
        options: {
            altBoundary: false,
            rootBoundary: 'viewport',
            padding: 8,
        },
    },
    {
        name: 'preventOverflow',
        enabled: true,
        options: {
            altAxis: true,
            altBoundary: true,
            tether: false,
            rootBoundary: 'viewport',
            padding: 8,
        },
    },
]

export const PopoverComments = (props: CommentsProps) => {
    const isMobileDevice = useIsMobileDevice()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const { getCommentsQuery } = useCommentsQueries(props)
    const commentsCount = getCommentsQuery.data?.length

    return (
        <>
            <IconButton
                aria-label="comments"
                onClick={handleToggle}
                size="large"
                sx={(theme) => ({
                    fontSize: theme.spacing(4),
                })}>
                <Badge badgeContent={commentsCount} color="primary">
                    <SvgIcon component={CommentsIcon} inheritViewBox color="primary" fontSize="inherit" />
                </Badge>
            </IconButton>

            <Popper
                disablePortal
                open={!!anchorEl}
                anchorEl={anchorEl}
                placement={isMobileDevice ? 'bottom' : 'right-start'}
                modifiers={POPOVER_MODIFIERS}
                sx={(theme) => ({ zIndex: theme.zIndex.drawer })}>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Fade {...TransitionProps} in={!!anchorEl} timeout={800} unmountOnExit mountOnEnter>
                            <Box
                                sx={{
                                    height: 'clamp(20rem, 50vh, 40rem)',
                                    width: 'clamp(320px, 25rem, 30rem)',
                                }}>
                                <Comments {...props} minimized />
                            </Box>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    )
}
