import React, { useEffect, useState } from 'react'
import '../../style/Views.scss'
import ListItemText from '@mui/material/ListItemText'
import CreateButton from '../Buttons/CreateButton'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { GapProgressBar } from '../GapAnalysis/GapProgressBar'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import MarketAccessGoal from '../GapAnalysis/MarketAccessGoal'
import Tab from '@mui/material/Tab'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ReactComponent as OngoingIcon } from '../../assets/images/orange_triangle.svg'
import { ReactComponent as CompletedIcon } from '../../assets/images/green_circle.svg'
import { setGapAnalysisView, setStrategy, setVMFilter } from '../../redux/actions'
import { connect, useDispatch, useSelector } from 'react-redux'
import store from '../../redux/store'
import { useAuth0 } from '../../contexts/auth0-context'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import { Fade, SvgIcon } from '@mui/material'
import _ from 'underscore'
import Paper from '@mui/material/Paper'
import Clinical_Icon from '../../assets/images/icon_VP_clinical.svg'
import Economic_Icon from '../../assets/images/icon_VP_economic.svg'
import Humanistic_Icon from '../../assets/images/icon_VP_humanistic3.svg'
import Unmet_Icon from '../../assets/images/icon_VP_unmet_need.svg'
import RWE_Icon from '../../assets/images/Icon_RWE.svg'
import { ACTIVITY_STATUSES, useActivityStatus } from '../../shared/hooks/useActivityStatus'
import { COLORS } from '../../style/colors'
import { useIsDarkMode } from '../../shared/hooks/useIsDarkMode'
import { PopoverComments } from '../../shared/components/Comments/variants/PopoverComments/PopoverComments'

function mapDispatchToProps(dispatch) {
    return {
        setStrategy: (strat) => dispatch(setStrategy(strat)),
        setVMFilter: (filter) => dispatch(setVMFilter(filter)),
        setGapAnalysisView: (filter) => dispatch(setGapAnalysisView(filter)),
    }
}

function ConnectedLeftPanel(props) {
    const snapshotMode = useSelector((state) => (state.strategy ? !!state.strategy.sourceId : false))
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))

    const buttonToCategoryGapMapping = {
        0: 'reimbursableFile',
        1: 'gapAssessment',
        2: 'evidenceGaps',
    }

    const buttonToCategoryMapping = {
        0: 'Unmet Need',
        1: 'Clinical',
        2: 'Economic',
        3: 'Humanistic',
    }

    const { user, isAuthenticated, getTokenSilently } = useAuth0()

    const isDarkMode = useIsDarkMode()
    const strategyId = useSelector((state) => state.strategy?._id)

    let vmFilter = useSelector((state) => state.valueMessageFilterMapping)
    let storeGapAnalysis = useSelector((state) => state.gapAnalysisView)

    const dispatch = useDispatch()

    const { createNew, filter } = props
    const [settings, setSettings] = useState({})
    const [selectedIndex, setSelectedIndex] = useState(vmFilter)
    const [tabValue, setTabValue] = useState(vmFilter)
    const [selectedGapIndex, setSelectedGapIndex] = useState(storeGapAnalysis)
    const [gapAnalysis, setGapAnalysis] = useState({})
    const [gapDescription, setGapDescription] = useState('')
    const [actCategories, setActCategories] = useState([])
    const [newGapDescription, setNewGapDescription] = useState('')
    const [isLoaded, setIsLoaded] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(false)
    const [comments, setComments] = useState({
        valueMessageTable: {
            'Unmet Need': [],
            Clinical: [],
            Economic: [],
            Humanistic: [],
        },
        activityTable: [],
        evidenceTable: [],
    })

    const { handleGetActivityStatusDetails } = useActivityStatus()

    useEffect(() => {
        if (props.id === 'vm') {
            setSelectedIndex(vmFilter)
            setTabValue(vmFilter)
            filter(buttonToCategoryMapping[vmFilter])
        }
        if (props.id === 'gap') {
            setSelectedIndex(vmFilter)
            setTabValue(vmFilter)
            setSelectedGapIndex(storeGapAnalysis)
            filter(buttonToCategoryGapMapping[storeGapAnalysis])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vmFilter, storeGapAnalysis])

    async function getSettings() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingSettings = await callAPI(
            endpoints.getSettingsByOrganizationId.url + store.getState().strategy.organizationId,
            endpoints.getSettingsByOrganizationId.method,
            token
        )
        if (incomingSettings.success) {
            setSettings(incomingSettings.data)
        }
        setIsLoaded(true)

        let actCategories = settings.activityCategories
        setActCategories(actCategories)
    }

    useEffect(() => {
        if (forceRefresh) {
            setForceRefresh(false)
        }
        if (user && isAuthenticated) {
            getSettings()
            // getUsers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, forceRefresh])

    const handleListItemClick = (event, index) => {
        props.setVMFilter(index)
    }

    const handleGapViewChange = (event) => {
        props.setGapAnalysisView(event.target.value)
    }

    const handleTabChange = (event, newValue) => {
        dispatch(setVMFilter(newValue))
        setTabValue(newValue)
    }

    async function updateRating(newRating) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(endpoints.updateGapAnalysis.url + gapAnalysis._id, endpoints.updateGapAnalysis.method, token, {
            overallSummaryRating: newRating,
            description: gapAnalysis.description,
            goals: gapAnalysis.goals,
            strategyId: gapAnalysis.strategyId,
            organizationId: gapAnalysis.organizationId,
            gapAssessment: gapAnalysis.gapAssessment,
            reimbursableFile: gapAnalysis.reimbursableFile,
        })
        setForceRefresh(true)
    }

    async function deleteGoal(index) {
        // updating gap analysis with new goals
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(
            endpoints.deleteMarketAccessGoal.url + gapAnalysis._id + '/' + index,
            endpoints.deleteMarketAccessGoal.method,
            token
        )
        setForceRefresh(true)
    }

    async function saveDescription() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        let newDescription = {
            goals: gapAnalysis.goals,
            overallSummaryRating: gapAnalysis.overallSummaryRating,
            description: newGapDescription,
            strategyId: gapAnalysis.strategyId,
            organizationId: gapAnalysis.organizationId,
            gapAssessment: gapAnalysis.gapAssessment,
            reimbursableFile: gapAnalysis.reimbursableFile,
        }

        callAPI(
            endpoints.updateGapAnalysis.url + gapAnalysis._id,
            endpoints.updateGapAnalysis.method,
            token,
            newDescription
        )
        setGapDescription(newGapDescription)
    }

    const VmCategories = [
        { text: _.isEmpty(settings) ? null : settings['vmCategoryDisplayNames']['Unmet Need'] },
        { text: _.isEmpty(settings) ? null : settings['vmCategoryDisplayNames']['Clinical'] },
        { text: _.isEmpty(settings) ? null : settings['vmCategoryDisplayNames']['Economic'] },
        { text: _.isEmpty(settings) ? null : settings['vmCategoryDisplayNames']['Humanistic'] },
    ]

    const VmCategoriesNoDisplayNames = ['Unmet Need', 'Clinical', 'Economic', 'Humanistic']

    const activityStatusesContainerStyles = {
        p: 2,
        borderRadius: 4,
        boxShadow: 0,
        backgroundColor: isDarkMode ? 'background.paper' : COLORS.lightQuillGrey,
    }

    if (props.id === 'vm') {
        return (
            <Fade in={isLoaded} timeout={800} unmountOnExit mountOnEnter>
                <Grid container py={2} alignItems="flex-start">
                    <Grid container item xs spacing={2} px={2}>
                        <Grid container item xs="auto" alignItems="center" spacing={2}>
                            <Grid item>
                                <SvgIcon component={props.imgsrc} fontSize="large" alt="page icon" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h1">{props.text}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item>
                                {!snapshotMode && !userReadonly && <CreateButton id={props.id} createNew={createNew} />}
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth size="small">
                                    <Select value={selectedIndex} style={{ borderRadius: 14, width: 180, height: 52 }}>
                                        {VmCategories.map((category, index) => (
                                            <MenuItem
                                                value={index}
                                                selected={selectedIndex === index}
                                                onClick={(event) => handleListItemClick(event, index)}>
                                                <ListItemText
                                                    primary={<Typography variant="title2">{category.text}</Typography>}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <PopoverComments
                                    dataId={strategyId}
                                    dataType="valueMessageTable"
                                    dataCategory={VmCategoriesNoDisplayNames[selectedIndex]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs="auto" direction="column">
                        <Typography variant="h5" sx={{ mb: 0.5, pl: 2 }}>
                            Status
                        </Typography>
                        <Grid
                            container
                            item
                            xs="auto"
                            direction="column"
                            gap={0.5}
                            component={Paper}
                            elevation={1}
                            sx={activityStatusesContainerStyles}>
                            <Grid container item wrap="nowrap" xs="auto" spacing={1}>
                                <Grid item>
                                    <SvgIcon fontSize="small" component={CompletedIcon} inheritViewBox />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body3">Known</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item wrap="nowrap" xs="auto" spacing={1}>
                                <Grid item>
                                    <SvgIcon fontSize="small" component={OngoingIcon} inheritViewBox />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body3">Aspirational</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
        )
    } else if (props.id === 'gap') {
        return (
            <Fade in={isLoaded} timeout={800}>
                <Grid container item spacing={2} justifyContent="space-between">
                    <Grid container item xs="auto" direction="column">
                        <Grid container item spacing={2} xs="auto" sx={{ mb: 2 }}>
                            <Grid item>
                                <SvgIcon component={props.imgsrc} fontSize="large" alt="page icon" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h1">{props.text}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs="auto">
                            <FormControl fullWidth>
                                <Select
                                    labelId="gap-view-select-label"
                                    id="gap-view-select"
                                    sx={{ width: 'max-content', borderRadius: 3, mr: 5 }}
                                    value={selectedGapIndex}
                                    onChange={handleGapViewChange}>
                                    <MenuItem value={'reimbursableFile'}>Reimbursable File</MenuItem>
                                    <MenuItem value={'gapAssessment'}>Market Access Gaps</MenuItem>
                                    <MenuItem value={'evidenceGaps'}>Evidence Gaps</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {storeGapAnalysis !== 'evidenceGaps' && (
                        <Grid container item xs={3}>
                            <GapProgressBar rating={gapAnalysis.overallSummaryRating} updateRating={updateRating} />
                        </Grid>
                    )}
                    {storeGapAnalysis && storeGapAnalysis === 'gapAssessment' && (
                        <Grid
                            container
                            item
                            component={Paper}
                            xs={3.5}
                            p={1}
                            margin={2}
                            paddingLeft={2}
                            sx={{ borderRadius: 4, boxShadow: 'none' }}>
                            <Grid container item spacing={1} alignItems="center" flexWrap="nowrap">
                                <Grid item>
                                    <span className="greenLegend" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                                        Evidence available & meets/exceeds expectations
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} alignItems="center" flexWrap="nowrap">
                                <Grid item>
                                    <span className="yellowLegend" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                                        Evidence uncertain
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} alignItems="center" flexWrap="nowrap">
                                <Grid item>
                                    <span className="redLegend" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                                        Evidence not available/ below expectations
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} alignItems="center" flexWrap="nowrap">
                                <Grid item>
                                    <span className="blueLegend" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                                        Evidence not relevant
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        // <Grid container item xs={3}>
                        //     <List disablePadding>
                        //         <ListItem disablePadding>
                        //             <ListItemIcon className="greenLegend"></ListItemIcon>
                        //             <ListItemText>
                        //                 <Typography variant="body3">
                        //                     Evidence available & meets/exceeds expectations
                        //                 </Typography>
                        //             </ListItemText>
                        //         </ListItem>
                        //         <ListItem disablePadding>
                        //             <ListItemIcon className="yellowLegend"></ListItemIcon>
                        //             <ListItemText>
                        //                 <Typography variant="body3">Evidence uncertain</Typography>
                        //             </ListItemText>
                        //         </ListItem>
                        //         <ListItem disablePadding>
                        //             <ListItemIcon className="redLegend"></ListItemIcon>
                        //             <ListItemText>
                        //                 <Typography variant="body3">
                        //                     Evidence not available/ below expectations
                        //                 </Typography>
                        //             </ListItemText>
                        //         </ListItem>
                        //         <ListItem disablePadding>
                        //             <ListItemIcon className="blueLegend"></ListItemIcon>
                        //             <ListItemText>
                        //                 <Typography variant="body3">Evidence not relevant</Typography>
                        //             </ListItemText>
                        //         </ListItem>
                        //     </List>
                        // </Grid>
                    )}
                    {storeGapAnalysis && storeGapAnalysis === 'reimbursableFile' && (
                        <Grid
                            container
                            item
                            component={Paper}
                            xs={3.5}
                            p={1}
                            margin={2}
                            paddingLeft={2}
                            sx={{ borderRadius: 4, boxShadow: 'none' }}>
                            <Grid container item spacing={1} alignItems="center" flexWrap="nowrap">
                                <Grid item>
                                    <span className="greenLegend" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                                        Evidence available for the identified market
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} alignItems="center" flexWrap="nowrap">
                                <Grid item>
                                    <span className="yellowLegend" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                                        Evidence will be available for the identified market
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} alignItems="center" flexWrap="nowrap">
                                <Grid item>
                                    <span className="orangeLegend" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                                        Evidence will be leveraged from other countries
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} alignItems="center" flexWrap="nowrap">
                                <Grid item>
                                    <span className="redLegend" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                                        Evidence gap for the identified market
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} alignItems="center" flexWrap="nowrap">
                                <Grid item>
                                    <span className="blueLegend" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                                        Evidence NOT relevant for the identified market
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        // <Grid container item xs="auto">
                        //     <List disablePadding>
                        //         <ListItem disablePadding>
                        //             <ListItemIcon className="greenLegend"></ListItemIcon>
                        //             <ListItemText>
                        //                 <Typography variant="body3">
                        //                     Evidence available for the identified market
                        //                 </Typography>
                        //             </ListItemText>
                        //         </ListItem>
                        //         <ListItem disablePadding>
                        //             <ListItemIcon className="yellowLegend"></ListItemIcon>
                        //             <ListItemText>
                        //                 <Typography variant="body3">
                        //                     Evidence will be available for the identified market
                        //                 </Typography>
                        //             </ListItemText>
                        //         </ListItem>
                        //         <ListItem disablePadding>
                        //             <ListItemIcon className="orangeLegend"></ListItemIcon>
                        //             <ListItemText>
                        //                 <Typography variant="body3">
                        //                     Evidence will be leveraged from other countries
                        //                 </Typography>
                        //             </ListItemText>
                        //         </ListItem>
                        //         <ListItem disablePadding>
                        //             <ListItemIcon className="redLegend"></ListItemIcon>
                        //             <ListItemText>
                        //                 <Typography variant="body3">Evidence gap for the identified market</Typography>
                        //             </ListItemText>
                        //         </ListItem>
                        //         <ListItem disablePadding>
                        //             <ListItemIcon className="blueLegend"></ListItemIcon>
                        //             <ListItemText>
                        //                 <Typography variant="body3">
                        //                     Evidence NOT relevant for the identified market
                        //                 </Typography>
                        //             </ListItemText>
                        //         </ListItem>
                        //     </List>
                        // </Grid>
                    )}

                    {storeGapAnalysis && storeGapAnalysis === 'evidenceGaps' && (
                        <Grid container item xs="auto">
                            <Grid container item>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    aria-label="value tabs selection">
                                    <Tab
                                        label={
                                            _.isEmpty(settings) ? '' : settings['vmCategoryDisplayNames']['Unmet Need']
                                        }
                                        value={0}
                                    />
                                    <Tab
                                        label={
                                            _.isEmpty(settings) ? '' : settings['vmCategoryDisplayNames']['Clinical']
                                        }
                                        value={1}
                                    />
                                    <Tab
                                        label={
                                            _.isEmpty(settings) ? '' : settings['vmCategoryDisplayNames']['Economic']
                                        }
                                        value={2}
                                    />
                                    <Tab
                                        label={
                                            _.isEmpty(settings) ? '' : settings['vmCategoryDisplayNames']['Humanistic']
                                        }
                                        value={3}
                                    />
                                </Tabs>
                            </Grid>
                            <Grid container item component={Paper} xs={2} p={1} margin={1} paddingLeft={2}>
                                <Grid container item spacing={1} alignItems="center">
                                    <Grid item>
                                        <span className="knownDot" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="caption">Open</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={1} alignItems="center">
                                    <Grid item>
                                        <span className="aspirationalDot" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="caption">Closed</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {storeGapAnalysis !== 'evidenceGaps' && (
                        <Grid container item direction="column" justifyContent="flex-end" spacing={1} xs="auto">
                            <Grid item xs="auto">
                                <TextField
                                    id="standard-multiline-flexible"
                                    multiline
                                    minRows={5}
                                    label={'Evidence Gap Summary:'}
                                    variant="filled"
                                    value={newGapDescription}
                                    disabled={snapshotMode || userReadonly}
                                    onChange={(e) => {
                                        setNewGapDescription(e.target.value)
                                    }}
                                    sx={{
                                        width: 300,
                                        paddingBottom: 2.1,
                                        '& .MuiInputLabel-filled': {
                                            // Target the filled variant label
                                            fontWeight: 'bold', // Apply bold font weight
                                        },
                                        '& .MuiFilledInput-root': {
                                            borderRadius: 3,
                                            position: 'relative',
                                            '&:after': {
                                                // Underline color when textfield is active (focused)
                                                borderBottom: '2px solid',
                                                borderBottomColor: 'primary.main',
                                                left: '0.5em', // Adjust left position to account for border radius
                                                right: '0.5em', // Adjust right position to account for border radius
                                                bottom: 0, // Adjust bottom position if needed
                                            },
                                            '&:before': {
                                                // Underline color when textfield is inactive
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                left: '0.5em', // Adjust left position to account for border radius
                                                right: '0.5em', // Adjust right position to account for border radius
                                                bottom: 0, // Adjust bottom position if needed
                                            },
                                            '&:hover:not(.Mui-disabled):before': {
                                                // Underline color when textfield is hovered
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                                                left: '0.5em', // Adjust left position to account for border radius
                                                right: '0.5em', // Adjust right position to account for border radius
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            {!_.isEqual(gapDescription, newGapDescription) && (
                                <Grid container item spacing={1} xs justifyContent="flex-end">
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            sx={{ borderRadius: 3 }}
                                            onClick={() => {
                                                setNewGapDescription(gapDescription)
                                            }}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={saveDescription} sx={{ borderRadius: 3 }} variant="contained">
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    )}
                    {storeGapAnalysis && storeGapAnalysis === 'summary' && (
                        <Grid container item xs={3}>
                            {gapAnalysis.goals.map((goal, i) => (
                                <MarketAccessGoal goal={goal} index={i} deleteGoal={deleteGoal} />
                            ))}
                            {!gapAnalysis.goals ||
                                (gapAnalysis.goals.length === 0 && (
                                    <Grid container item>
                                        <Grid item>
                                            <Typography variant="h4">No Market Access Goals to display.</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                Create a new Market Access Goal on the dashboard to initialize the Gap
                                                Assessment.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                </Grid>
            </Fade>
        )
    } else if (props.id === 'evidence') {
        return (
            <Fade in={isLoaded} timeout={800} unmountOnExit mountOnEnter>
                <Grid container pt={2} alignItems="flex-start">
                    <Grid container item xs px={2} spacing={2}>
                        <Grid container item xs="auto" alignItems="center" spacing={2}>
                            <Grid item>
                                <SvgIcon component={props.imgsrc} fontSize="large" alt="page icon" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h1">{props.text}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item>
                                {!snapshotMode && !userReadonly && <CreateButton id={props.id} createNew={createNew} />}
                            </Grid>
                            <Grid item>
                                <PopoverComments dataId={strategyId} dataType="evidenceTable" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item direction="column" xs="auto">
                        <Grid item xs="auto">
                            <Typography variant="h5" sx={{ mb: 0.5, pl: 2 }}>
                                Value Pillars
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs="auto"
                            display="grid"
                            gridTemplateColumns="repeat(2, 1fr)"
                            gridAutoFlow="row"
                            columnGap={1.5}
                            rowGap={0.5}
                            component={Paper}
                            elevation={1}
                            sx={activityStatusesContainerStyles}
                            pr={'0 !important'}
                            pl={'24px !important'}>
                            <Grid container item spacing={1} wrap="nowrap" xs="auto">
                                <Grid item display="flex">
                                    <img src={Unmet_Icon} alt="Unmet Need Icon" className="evidencePillars" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" noWrap>
                                        {_.isEmpty(settings) ? '' : settings['vmCategoryDisplayNames']['Unmet Need']}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item spacing={1} wrap="nowrap" xs="auto">
                                <Grid item display="flex">
                                    {settings['vmCategoryDisplayNames'] &&
                                    settings['vmCategoryDisplayNames']['Clinical'].includes('Patient Benefit') ? (
                                        <img
                                            src={Humanistic_Icon}
                                            alt="Humanistic User Icon"
                                            className="evidencePillars"
                                        />
                                    ) : (
                                        <img src={Clinical_Icon} alt="Clinical Pill Icon" className="evidencePillars" />
                                    )}
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        {_.isEmpty(settings) ? '' : settings['vmCategoryDisplayNames']['Clinical']}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item spacing={1} wrap="nowrap" xs="auto">
                                <Grid item display="flex">
                                    {settings['vmCategoryDisplayNames'] &&
                                    settings['vmCategoryDisplayNames']['Humanistic'].includes('Economic Value') ? (
                                        <img src={Economic_Icon} alt="Economic Icon" className="evidencePillars" />
                                    ) : (
                                        <img src={Humanistic_Icon} alt="Humanistic Icon" className="evidencePillars" />
                                    )}
                                </Grid>
                                <Grid item>
                                    <Typography variant="body3">
                                        {_.isEmpty(settings) ? '' : settings['vmCategoryDisplayNames']['Humanistic']}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item spacing={1} wrap="nowrap" xs="auto">
                                <Grid item display="flex">
                                    {settings['vmCategoryDisplayNames'] &&
                                    settings['vmCategoryDisplayNames']['Economic'].includes('Real-world Evidence') ? (
                                        <img
                                            src={RWE_Icon}
                                            alt="Real World Evidence Icon"
                                            className="evidencePillars"
                                        />
                                    ) : (
                                        <img src={Economic_Icon} alt="Economic Icon" className="evidencePillars" />
                                    )}
                                </Grid>
                                <Grid item>
                                    <Typography variant="body3">
                                        {_.isEmpty(settings) ? '' : settings['vmCategoryDisplayNames']['Economic']}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
        )
    } else if (props.id === 'activity') {
        return (
            <Fade in={isLoaded} timeout={800} unmountOnExit mountOnEnter>
                <Grid container pt={2} alignItems="flex-start">
                    <Grid container item xs spacing={2} px={2}>
                        <Grid container item xs="auto" alignItems="center" spacing={2}>
                            <Grid item>
                                <SvgIcon component={props.imgsrc} fontSize="large" alt="page icon" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h1">{props.text}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item>
                                {!snapshotMode && !userReadonly && <CreateButton id={props.id} createNew={createNew} />}
                            </Grid>
                            <Grid item>
                                <PopoverComments dataId={strategyId} dataType="activityTable" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs="auto" direction="column">
                        <Grid item>
                            <Typography variant="h5" sx={{ mb: 0.5, pl: 2 }}>
                                Status
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs="auto"
                            display="grid"
                            gridTemplateColumns="repeat(2, 1fr)"
                            gridAutoFlow="row"
                            columnGap={1}
                            rowGap={0.5}
                            component={Paper}
                            elevation={1}
                            sx={activityStatusesContainerStyles}>
                            {ACTIVITY_STATUSES.map((activityStatus) => {
                                const activityStatusDetails = handleGetActivityStatusDetails(activityStatus)
                                return (
                                    <Grid container item wrap="nowrap" xs="auto" spacing={1}>
                                        <Grid item display="flex" alignItems="center">
                                            <SvgIcon
                                                component={activityStatusDetails.icon}
                                                inheritViewBox
                                                fontSize="small"
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="body2" noWrap>
                                                {activityStatusDetails.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
        )
    } else {
        return (
            <Fade in={isLoaded} timeout={800} unmountOnExit mountOnEnter>
                <Grid container alignItems="flex-start" spacing={2} p={2} pb={0}>
                    <Grid container item xs="auto" alignItems="center" spacing={2}>
                        <Grid item>
                            <SvgIcon component={props.imgsrc} alt="page icon" fontSize="large" />
                        </Grid>
                        <Grid item>
                            <Typography variant="h1">{props.text}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item>
                            {!snapshotMode && !userReadonly && <CreateButton id={props.id} createNew={createNew} />}
                        </Grid>
                        {props.id === 'strategicimperative' && (
                            <Grid item>
                                <PopoverComments dataId={strategyId} dataType="strategicImperativeTable" />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Fade>
        )
    }
}

const LeftPanel = connect(null, mapDispatchToProps)(ConnectedLeftPanel)

export default LeftPanel
