import React, { useEffect, useState } from 'react'
import TablePanel from '../Support/TablePanel'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import GetAppIcon from '@mui/icons-material/GetApp'
import Typography from '@mui/material/Typography'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Grid from '@mui/material/Grid'
import LinkIcon from '@mui/icons-material/Link'
import Fade from '@mui/material/Fade'
import * as _ from 'underscore'
import Link from '@mui/material/Link'
import store from '../../redux/store'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import { useAuth0 } from '../../contexts/auth0-context'
import callFileAPI from '../../API/callFileAPI'
import download from 'downloadjs'
import Clinical_Icon from '../../assets/images/icon_VP_clinical.svg'
import Economic_Icon from '../../assets/images/icon_VP_economic.svg'
import Humanistic_Icon from '../../assets/images/icon_VP_humanistic3.svg'
import Unmet_Icon from '../../assets/images/icon_VP_unmet_need.svg'
import RWE_Icon from '../../assets/images/Icon_RWE.svg'
import { connect, useSelector } from 'react-redux'
import { setEvidenceTableNumberOfItems } from '../../redux/actions'
import {
    EnhancedTableHead,
    getComparator,
    stableSort,
    timeout,
} from '../../utilities/helpers'
import IconButton from '@mui/material/IconButton'
import { GlobalStyles } from '@mui/material'
import { EmptyPlaceholder } from '../../shared/components/EmptyPlaceholder'
import { arrayMove } from '@dnd-kit/sortable'
import { useIsDarkMode } from '../../shared/hooks/useIsDarkMode'
import { SortableTable } from '../../shared/components/SortableTable'

function createData(name, type, link, s3Name, year, value, id, order, panel) {
    return { name, type, link, s3Name, year, value, id, order, panel }
}

function mapDispatchToProps(dispatch) {
    return {
        setEvidenceTableNumberOfRows: (num) => dispatch(setEvidenceTableNumberOfItems(num)),
    }
}

function ConnectedEvidenceTable(props) {
    const { isAuthenticated, getTokenSilently } = useAuth0()
    const [page, setPage] = useState(0)
    const [dataRows, setDataRows] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(false)
    const itemsToDisplay = useSelector((state) => state.evidenceTableNumberOfItems || 10)
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('')
    const [vmData, setVMData] = useState({})
    const [evidenceData, setEvidenceData] = useState({})
    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const [evidenceForPanel, setEvidenceForPanel] = useState(
        props?.autoOpenState?.isAutoOpenPanel ? props.autoOpenState.autoOpenData : {}
    )
    const [updateTableFromPanel, setUpdateTableFromPanel] = useState(false)
    const [navigationStack, setNavigationStack] = useState([])

    const isDarkMode = useIsDarkMode();

    const { newFormData } = props
    // Columns declared as const weren't loading Store in time for render
    // eslint-disable-next-line no-unused-vars
    const [columns, setColumns] = useState([
        { id: 'index', label: '' },
        { id: 'name', label: 'Title' },
        { id: 'type', label: 'Type', align: 'center', headPad: 2 },
        { id: 'link', label: 'Link', align: 'center', headPad: 2.5 },
        { id: 's3Name', label: 'File', align: 'center', headPad: 2.5 },
        { id: 'year', label: 'Year', align: 'center', headPad: 2.5 },
        {
            id: 'value',
            label:
                (store.getState().settings?.displayNames?.valuePillar &&
                    store.getState().settings?.displayNames?.valuePillar) ||
                'Loading',
            format: (value) => value.toLocaleString('en-US'),
            align: 'center',
            headPad: 2,
        },
        { id: 'panel', label: '' },
    ])

    useEffect(() => {
        // This fires when component mounts and any time the VM list is modified
        if (forceRefresh) {
            setForceRefresh(false)
        }

        if (!isAuthenticated) {
            return
        }

        if (updateTableFromPanel) {
            setUpdateTableFromPanel(false)
        }

        getEvidenceData()
        checkForAutoPanel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefresh, isAuthenticated, updateTableFromPanel])

    useEffect(() => {
        // track 'newFormData' from props for changes
        // if it changes, it means the Create Button is sending data
        if (!_.isEmpty(newFormData)) {
            newEvidence(newFormData).then(() => {
                setForceRefresh(true)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newFormData])

    useEffect(() => {
        checkForAutoPanel()
    }, [props])

    async function getEvidenceData() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingEvidence = await callAPI(
            endpoints.getEvidenceForTable.url + store.getState().strategy._id,
            endpoints.getEvidenceForTable.method,
            token
        )

        if (incomingEvidence.success) {
            createDataRows(
                incomingEvidence.data.length > 0 ? incomingEvidence.data.sort((a, b) => a.order - b.order) : []
            )
        } else {
            createDataRows([])
            setLoaded(true)
            return
        }
        let evData = {}
        for (let ev of incomingEvidence.data) {
            evData[ev._id] = ev
        }
        setEvidenceData(evData)
        if (evidenceForPanel) {
            setEvidenceForPanel(evData[evidenceForPanel._id])
        }
    }

    async function newEvidence() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(endpoints.createEvidence.url, endpoints.createEvidence.method, token, newFormData)
    }

    async function createDataRows(data) {
        let arr = []
        let newObj = {}
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        let vmObj = { 'Unmet Need': [], Humanistic: [], Economic: [], Clinical: [] }
        let vms = await callAPI(
            endpoints.getValueMessagesForTable.url + store.getState().strategy._id,
            endpoints.getValueMessagesForTable.method,
            token
        )
        if (vms.success) {
            for (let vm of vms.data) {
                vmObj[vm.category].push(vm._id)
            }
            setVMData(vmObj)
        }

        if (data && data.length) {
            let newArr = []
            for (const evidence of data) {
                let dataRow = await createData(
                    evidence.name,
                    evidence.category,
                    evidence.link,
                    evidence.s3Name,
                    evidence.year,
                    evidence.linkedValueMessages,
                    evidence._id,
                    evidence.order
                )
                newArr.push(dataRow)
            }
            newArr.sort((a, b) => a.order - b.order)
            setDataRows(newArr)
        }
        setLoaded(true)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        props.setEvidenceTableNumberOfRows(+event.target.value)
        setPage(0)
    }

    async function downloadFile(evidenceId) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        let evidence = await callAPI(endpoints.getEvidence.url + evidenceId, endpoints.getEvidence.method, token)
        let file = await callFileAPI(
            endpoints.downloadEvidenceFile.url + evidenceId,
            endpoints.downloadEvidenceFile.method,
            token
        )
        download(file, evidence.data.originalFileName, file.type)
    }

    const handleSortDataRows = async (sortingEvent) => {
        const { active, over } = sortingEvent;

        if (!over || active.id === over.id) {
            return;
        }

        const indexOfDestinationRow = sortingEvent.over.data.current.sortable.index;
        const indexOfSourceRow = sortingEvent.active.data.current.sortable.index;
        const newDataRows = arrayMove(
            dataRows,
            indexOfSourceRow,
            indexOfDestinationRow,
        );
        setDataRows(newDataRows);

        const token = await getTokenSilently({
            audience: 'https://atlas.aesara.com',
        });
        const ids = newDataRows.map((row) => row.id);
        await callAPI(endpoints.reorderEvidences.url, endpoints.reorderEvidences.method, token, ids)

        setOrderBy('index');
        setForceRefresh(true);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    function checkLinkedVMsForPillars(linkedVMs, category) {
        for (let vm of linkedVMs) {
            if (vmData[category].includes(vm)) {
                return true
            }
        }
        return false
    }

    function handlePanelOpen(id) {
        setEvidenceForPanel(evidenceData[id])
        setIsPanelOpen(true)
    }

    function handlePanelClose() {
        setEvidenceForPanel({})
        setNavigationStack([])
        setIsPanelOpen(false)
    }

    async function checkForAutoPanel() {
        if (_.get(props, ['autoOpenState', 'isAutoOpenPanel'], false)) {
            setEvidenceForPanel(props.autoOpenState.autoOpenData)
            setNavigationStack(props.autoOpenState.previousDataStack ? props.autoOpenState.previousDataStack : [])
            await timeout(700)
            setIsPanelOpen(true)
        }
    }

    const globalStyles = (
        <GlobalStyles
            styles={{
                '.MuiTableCell-root': {
                    borderBottom: 'none !important',
                },
            }}
        />
    )

    const sortedDataRows = stableSort(dataRows, getComparator(order, orderBy)).slice(page * itemsToDisplay, page * itemsToDisplay + itemsToDisplay)
    const isEvidenceDataEmpty = !sortedDataRows?.length;

    return (
        <>
            {globalStyles}
            <Fade in={loaded} timeout={800}>
                <Grid container item xs>
                    {isEvidenceDataEmpty && (
                        <EmptyPlaceholder
                            title="No Evidence to display"
                            subtitle="Click 'Add New' on the left panel to create a new Evidence"
                        />
                    )}
                    {!isEvidenceDataEmpty && (
                        <>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <EnhancedTableHead
                                        columns={columns}
                                        order={order}
                                        orderBy={orderBy}
                                        align={columns.align}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <SortableTable.Body data={sortedDataRows} onSort={handleSortDataRows}>
                                        {({rowData, index, rowProps}) => (
                                            <SortableTable.Row {...rowProps}>
                                                {columns.map((column) => {
                                                    const value = rowData[column.id]
                                                    if (column.id === 's3Name' && value && value.length > 0) {
                                                        return (
                                                            <TableCell
                                                                hover
                                                                key={column.id}
                                                                align={column.align}
                                                                sx={{ border: 'none' }}>
                                                                {/*eslint-disable-next-line*/}
                                                                <a href="#">
                                                                    <GetAppIcon sx={{ color: isDarkMode ? '#389BFF' : 'primary' }} onClick={() => downloadFile(rowData.id)} />
                                                                </a>
                                                            </TableCell>
                                                        )
                                                    } else if (column.id === 'index') {
                                                        return (
                                                            <TableCell
                                                                hover
                                                                key={column.id}
                                                                align={column.align}
                                                                sx={{
                                                                    color: '#808080',
                                                                    border: 'none',
                                                                    borderTopLeftRadius: index === 0 ? 14 : 0,
                                                                    borderBottomLeftRadius:
                                                                        index ===
                                                                        dataRows.slice(
                                                                            page * itemsToDisplay,
                                                                            (page + 1) * itemsToDisplay
                                                                        ).length -
                                                                        1
                                                                            ? 14
                                                                            : 0,
                                                                }}>
                                                                <Typography variant="body9">
                                                                    {index + page * itemsToDisplay + 1}.
                                                                </Typography>
                                                            </TableCell>
                                                        )
                                                    } else if (column.id === 'link' && value && value.length > 0) {
                                                        let formattedLink =
                                                            value.includes('http://') || value.includes('https://')
                                                                ? value
                                                                : 'http://' + value

                                                        return (
                                                            <TableCell
                                                                hover
                                                                key={column.id}
                                                                align={column.align}
                                                                sx={{ border: 'none' }}>
                                                                <Link
                                                                    underline="none"
                                                                    href={formattedLink}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer">
                                                                    <LinkIcon />
                                                                </Link>
                                                            </TableCell>
                                                        )
                                                    } else if (column.id === 'value') {
                                                        return (
                                                            <TableCell
                                                                hover
                                                                align={column.align}
                                                                key={column.id}
                                                                sx={{ border: 'none' }}>
                                                                <Grid container item wrap="nowrap" spacing={1}>
                                                                    <Grid item>
                                                                        <img
                                                                            src={Unmet_Icon}
                                                                            alt="Unmet Need Icon"
                                                                            className={
                                                                                checkLinkedVMsForPillars(
                                                                                    rowData.value,
                                                                                    'Unmet Need'
                                                                                )
                                                                                    ? 'evidencePillars'
                                                                                    : 'inactiveEvidencePillars'
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    {store.getState().settings[
                                                                        'vmCategoryDisplayNames'
                                                                        ] &&
                                                                    store
                                                                        .getState()
                                                                        .settings['vmCategoryDisplayNames'][
                                                                        'Clinical'
                                                                        ].includes('Patient Benefit') ? (
                                                                        <Grid item>
                                                                            <img
                                                                                src={Humanistic_Icon}
                                                                                alt="Humanistic User Icon"
                                                                                className={
                                                                                    checkLinkedVMsForPillars(
                                                                                        rowData.value,
                                                                                        'Clinical'
                                                                                    )
                                                                                        ? 'evidencePillars'
                                                                                        : 'inactiveEvidencePillars'
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    ) : (
                                                                        <Grid item>
                                                                            <img
                                                                                src={Clinical_Icon}
                                                                                alt="Clinical Pill Icon"
                                                                                className={
                                                                                    checkLinkedVMsForPillars(
                                                                                        rowData.value,
                                                                                        'Clinical'
                                                                                    )
                                                                                        ? 'evidencePillars'
                                                                                        : 'inactiveEvidencePillars'
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    {store.getState().settings[
                                                                        'vmCategoryDisplayNames'
                                                                        ] &&
                                                                    store
                                                                        .getState()
                                                                        .settings['vmCategoryDisplayNames'][
                                                                        'Humanistic'
                                                                        ].includes('Economic Value') ? (
                                                                        <Grid item>
                                                                            <img
                                                                                src={Economic_Icon}
                                                                                alt="Economic Icon"
                                                                                className={
                                                                                    checkLinkedVMsForPillars(
                                                                                        rowData.value,
                                                                                        'Humanistic'
                                                                                    )
                                                                                        ? 'evidencePillars'
                                                                                        : 'inactiveEvidencePillars'
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    ) : (
                                                                        <Grid item>
                                                                            <img
                                                                                src={Humanistic_Icon}
                                                                                alt="Humanistic Icon"
                                                                                className={
                                                                                    checkLinkedVMsForPillars(
                                                                                        rowData.value,
                                                                                        'Humanistic'
                                                                                    )
                                                                                        ? 'evidencePillars'
                                                                                        : 'inactiveEvidencePillars'
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    {store.getState().settings[
                                                                        'vmCategoryDisplayNames'
                                                                        ] &&
                                                                    store
                                                                        .getState()
                                                                        .settings['vmCategoryDisplayNames'][
                                                                        'Economic'
                                                                        ].includes('Real-world Evidence') ? (
                                                                        <Grid item>
                                                                            <img
                                                                                src={RWE_Icon}
                                                                                alt="Real World Evidence Icon"
                                                                                className={
                                                                                    checkLinkedVMsForPillars(
                                                                                        rowData.value,
                                                                                        'Economic'
                                                                                    )
                                                                                        ? 'evidencePillars'
                                                                                        : 'inactiveEvidencePillars'
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    ) : (
                                                                        <Grid item>
                                                                            <img
                                                                                src={Economic_Icon}
                                                                                alt="Economic Icon"
                                                                                className={
                                                                                    checkLinkedVMsForPillars(
                                                                                        rowData.value,
                                                                                        'Economic'
                                                                                    )
                                                                                        ? 'evidencePillars'
                                                                                        : 'inactiveEvidencePillars'
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </TableCell>
                                                        )
                                                    } else if (column.id === 'panel') {
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                sx={{
                                                                    border: 'none',
                                                                    borderTopRightRadius: index === 0 ? 14 : 0,
                                                                    borderBottomRightRadius:
                                                                        index ===
                                                                        dataRows.slice(
                                                                            page * itemsToDisplay,
                                                                            (page + 1) * itemsToDisplay
                                                                        ).length -
                                                                        1
                                                                            ? 14
                                                                            : 0,
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => handlePanelOpen(rowData.id)}>
                                                                <IconButton sx={(theme) => ({ padding: theme.spacing(1.5), marginInline: theme.spacing(-0.75) })}>
                                                                    <ArrowForwardIosIcon color="primary" />
                                                                </IconButton>
                                                            </TableCell>
                                                        )
                                                    } else {
                                                        return (
                                                            <TableCell
                                                                hover
                                                                key={column.id}
                                                                align={column.align}
                                                                sx={{ border: 'none' }}
                                                                style={{
                                                                    whiteSpace: 'break-spaces',
                                                                }}>
                                                                <Typography
                                                                    variant={
                                                                        column.format && typeof value === 'number'
                                                                            ? 'h4'
                                                                            : 'body1'
                                                                    }
                                                                    color={
                                                                        column.format && typeof value === 'number'
                                                                            ? 'primary'
                                                                            : ''
                                                                    }>
                                                                    {column.format && typeof value === 'number'
                                                                        ? column.format(value)
                                                                        : value}
                                                                </Typography>
                                                            </TableCell>
                                                        )
                                                    }
                                                })}
                                            </SortableTable.Row>
                                        )}
                                    </SortableTable.Body>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={dataRows.length}
                                rowsPerPage={itemsToDisplay}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    )}
                    {isPanelOpen && (
                        <TablePanel
                            title={evidenceForPanel?.name}
                            data={evidenceForPanel}
                            type={'evidence'}
                            closePanel={handlePanelClose}
                            openPanelAction={true}
                            updateParent={setUpdateTableFromPanel}
                            navigationStack={navigationStack}
                        />
                    )}
                </Grid>
            </Fade>
        </>
    )
}

const EvidenceTable = connect(null, mapDispatchToProps)(ConnectedEvidenceTable)

export default EvidenceTable
