export const TABLE_COMMENT_DATA_TYPES = [
    'evidenceTable',
    'activityTable',
    'valueMessageTable',
    'strategicImperativeTable',
] as const

export const COMMENT_DATA_TYPES = [
    'evidence',
    'activity',
    'valueMessage',
    'strategicImperative',
    ...TABLE_COMMENT_DATA_TYPES,
] as const

export type CommentDataType = (typeof COMMENT_DATA_TYPES)[number]

export type CommentsProps = {
    dataId: string
    dataType: CommentDataType
    dataCategory?: string
    minimized?: boolean
}

export type Comment = {
    _id: string
    text: string
    user: {
        _id: string
        name: string
    }
    createdAt: string
}
