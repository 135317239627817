import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import Checkbox from '@mui/material/Checkbox'
import withStyles from '@mui/styles/withStyles'
import store from '../../redux/store'
import endpoints from '../../API/endpoints'
import callAPI from '../../API/callSecureAPI'
import { useAuth0 } from '../../contexts/auth0-context'
import { Fade } from '@mui/material/'
import _ from 'underscore'
import FilterListIcon from '@mui/icons-material/FilterList'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import Typography from '@mui/material/Typography'

export default function CreateLink(props) {
    const { user, isAuthenticated, getTokenSilently, loading } = useAuth0()
    const [open, setOpen] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [activities, setActivities] = useState([])
    const [evidence, setEvidence] = useState([])
    const [vMCategoriesMap, setVMCategoriesMap] = useState({})
    const [settings, setSettings] = useState({})
    const [isSortedActivities, setIsSortedActivities] = useState(false)
    const [sortedActivities, setSortedActivities] = useState([])
    const [valueMessages, setValueMessages] = useState([])

    useEffect(() => {
        if (user && isAuthenticated && !loading) {
            getData()
            getSettings()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    async function getSettings() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingSettings = await callAPI(
            endpoints.getSettingsByOrganizationId.url + store.getState().strategy.organizationId,
            endpoints.getSettingsByOrganizationId.method,
            token
        )
        if (incomingSettings.success) {
            setSettings(incomingSettings.data)
        }
    }

    async function getData() {
        let apiEndpoints = [
            endpoints.getActivitiesForTable,
            endpoints.getEvidenceForTable,
            endpoints.getValueMessagesForTable,
        ]
        let functions = [setActivities, setEvidence]
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        for (let i = 0; i < apiEndpoints.length; i++) {
            await callAPI(apiEndpoints[i].url + store.getState().strategy._id, apiEndpoints[i].method, token).then(
                (res) => {
                    if (i === 2) {
                        // When processing Value Messages
                        let vmCategories = {
                            'Unmet Need': [],
                            Clinical: [],
                            Economic: [],
                            Humanistic: [],
                        }
                        let toSort = Array.from(res.data ? res.data : [])
                        toSort.sort(function (a, b) {
                            return a.order - b.order
                        })

                        for (let vm of toSort) {
                            vmCategories[vm.category].push(vm)
                        }
                        setVMCategoriesMap(vmCategories)
                        setValueMessages(toSort)
                    } else if (i === 1) {
                        // When processing Evidence
                        let toSort = Array.from(res.data ? res.data : [])
                        toSort.sort((a, b) => a.name.localeCompare(b.name))
                        functions[i](toSort)
                    } else {
                        functions[i](res.data ? res.data : [])
                        setSortedActivities(Array.from(res.data).sort((a, b) => a.title.localeCompare(b.title)))
                    }
                    setIsLoaded(true)
                }
            )
        }
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleCancel = () => {
        setOpen(false)
    }

    const classes = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        formControl: {
            margin: theme.spacing(3),
        },
    }))

    function link() {
        let checkboxes = document.querySelectorAll('input[type=checkbox]')
        function getChecked() {
            var checked = []
            for (var i = 0; i < checkboxes.length; i++) {
                var checkbox = checkboxes[i]
                if (checkbox.checked) checked.push(checkbox.value)
            }
            return checked
        }
        var checked = getChecked()
        props.update(checked)
        setOpen(false)
    }

    const BlueCheckBox = withStyles({
        root: {
            color: 'secondary',
            '&$checked': {
                color: 'secondary',
            },
        },
        checked: {},
    })((props) => <Checkbox color="primary" value={props.val} {...props} />)

    const dialogueHeaderMapping = {
        vm: {
            header1:
                (store.getState().settings?.displayNames?.valueMessages &&
                    store.getState().settings?.displayNames?.valueMessages) ||
                'Loading',
        },
        activity: {
            header1:
                (store.getState().settings?.displayNames?.activities &&
                    store.getState().settings?.displayNames?.activities) ||
                'Loading',
        },
        evidence: {
            header1:
                (store.getState().settings?.displayNames?.evidence &&
                    store.getState().settings?.displayNames?.evidence) ||
                'Loading',
        },
        strategicimperative: {
            header1: _.isEmpty(store.getState().settings)
                ? ''
                : store.getState().settings.displayNames.strategicImperatives,
        },
    }

    const checkDefaultChecked = (data) => {
        if (!props.listItems) {
            return false
        }
        let res = props.listItems.map((item) => item._id)
        return res.includes(data._id)
    }

    return (
        <div>
            <Tooltip title="Link or Unlink Data" arrow>
                <Button aria-label="add" size="small" variant="contained-link-unlink" color="primary" onClick={handleClickOpen}>
                    <LinkOutlinedIcon /> / <LinkOffOutlinedIcon />
                </Button>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{dialogueHeaderMapping[props.id].header}</DialogTitle>
                <DialogContent>
                    <Fade in={isLoaded}>
                        {(() => {
                            switch (props.id) {
                                case 'vm':
                                    return (
                                        <div>
                                            <DialogContentText>
                                                <Typography variant="h2">{dialogueHeaderMapping[props.id].header1}</Typography>
                                            </DialogContentText>
                                            <FormControl component="fieldset" className={classes.formControl}>
                                                {!valueMessages.length ? (
                                                    <p>
                                                        {`No ${
                                                            store.getState().settings?.displayNames?.valueMessages ||
                                                            'Loading'
                                                        } to link`}
                                                    </p>
                                                ) : (
                                                    Object.keys(vMCategoriesMap).map((category, i) => (
                                                        <div key={'vm' + category + i}>
                                                            {vMCategoriesMap[category].length > 0 && (
                                                                <h4 style={{ textAlign: 'center' }}>
                                                                    {_.isEmpty(settings)
                                                                        ? ''
                                                                        : settings['vmCategoryDisplayNames'][category]}
                                                                </h4>
                                                            )}
                                                            {vMCategoriesMap[category].map((vm, i) => (
                                                                <FormControlLabel
                                                                    key={vm._id + i}
                                                                    control={
                                                                        <BlueCheckBox
                                                                            val={vm._id}
                                                                            defaultChecked={checkDefaultChecked(vm)}
                                                                        />
                                                                    }
                                                                    label={`${i + 1}. ${vm.name}`}
                                                                    className="checkboxSelector"
                                                                />
                                                            ))}
                                                        </div>
                                                    ))
                                                )}
                                            </FormControl>
                                        </div>
                                    )
                                case 'activity':
                                    return (
                                        <div>
                                            <DialogContentText
                                                sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                                <Typography variant="h2">{dialogueHeaderMapping[props.id].header1}</Typography>
                                                {activities && activities.length ? (
                                                    <Button
                                                        variant="contained-sort"
                                                        size="small"
                                                        endIcon={
                                                            isSortedActivities ? (
                                                                <FilterListIcon />
                                                            ) : (
                                                                <FilterListOffIcon />
                                                            )
                                                        }
                                                        onClick={() => setIsSortedActivities(!isSortedActivities)}>
                                                        {isSortedActivities ? 'Sort by Date Created' : 'Sort A-Z'}
                                                    </Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </DialogContentText>
                                            <FormControl component="fieldset" className={classes.formControl}>
                                                {isSortedActivities ? (
                                                    sortedActivities && sortedActivities.length ? (
                                                        sortedActivities.map((activity, i) => (
                                                            <FormControlLabel
                                                                key={'linkAct' + i}
                                                                control={
                                                                    <BlueCheckBox
                                                                        val={activity._id}
                                                                        defaultChecked={checkDefaultChecked(activity)}
                                                                        sx={{ mr: 0.5 }}
                                                                    />
                                                                }
                                                                label={activity.title}
                                                                className="checkboxSelector"
                                                            />
                                                        ))
                                                    ) : (
                                                        <p>
                                                            {`No ${
                                                                store.getState().settings?.displayNames?.activities ||
                                                                'Loading'
                                                            } to link`}
                                                        </p>
                                                    )
                                                ) : activities && activities.length ? (
                                                    activities.map((activity, i) => (
                                                        <FormControlLabel
                                                            key={'linkAct' + i}
                                                            control={
                                                                <BlueCheckBox
                                                                    val={activity._id}
                                                                    defaultChecked={checkDefaultChecked(activity)}
                                                                    sx={{ mr: 0.5 }}
                                                                />
                                                            }
                                                            label={activity.title}
                                                            className="checkboxSelector"
                                                        />
                                                    ))
                                                ) : (
                                                    <p>{`No ${
                                                        store.getState().settings?.displayNames?.activities || 'Loading'
                                                    } to link`}</p>
                                                )}
                                            </FormControl>
                                        </div>
                                    )
                                case 'evidence':
                                    return (
                                        <div>
                                            <DialogContentText sx={{ mb: 2 }}>
                                                <Typography variant="h2">{dialogueHeaderMapping[props.id].header1}</Typography>
                                            </DialogContentText>
                                            <FormControl component="fieldset" className={classes.formControl}>
                                                {evidence && evidence.length ? (
                                                    evidence.map((evidence, i) => (
                                                        <FormControlLabel
                                                            key={'linkEv' + i}
                                                            control={
                                                                <BlueCheckBox
                                                                    val={evidence._id}
                                                                    defaultChecked={checkDefaultChecked(evidence)}
                                                                    sx={{ mr: 0.5 }}
                                                                />
                                                            }
                                                            label={evidence.name}
                                                            className="checkboxSelector"
                                                        />
                                                    ))
                                                ) : (
                                                    <p>
                                                        No{' '}
                                                        {(store.getState().settings?.displayNames?.evidence &&
                                                            store.getState().settings?.displayNames?.evidence) ||
                                                            'Loading'}{' '}
                                                        to link
                                                    </p>
                                                )}
                                            </FormControl>
                                        </div>
                                    )
                                default:
                                    return <p></p>
                            }
                        })()}
                    </Fade>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} variant="outlined-sm-button" sx={{ ml: 1, mb: 1, mt: 1 }}>
                        Cancel
                    </Button>
                    <Button className={classes.btnStyle} onClick={link} variant="contained-sm-button" sx={{ mb: 1, mt: 1, mr: 1 }}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
