import React, { useEffect, useState } from 'react'
import '../../style/detailed-view.scss'
import { useStyles } from '../../style/views'
import { WhiteOutlineCheckbox, BlueCheckBox } from '../Support/CustomElements'
import Alert from '@mui/material/Alert'
import { useTheme } from '@mui/styles'
import Typography from '@mui/material/Typography'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import icon_pin from '../../assets/images/icon_pin.png'
import icon_pin_hole from '../../assets/images/icon_pin_hole.svg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Fab,
    Fade,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    Tooltip,
} from '@mui/material/'
import { a11yProps, TabPanel } from '../Support/TabPanel'
import Slide from '@mui/material/Slide'
import CreateLink from '../Buttons/CreateLink'
import QuarterDatePicker from '../../utilities/quarterDatePicker'
import DateProgressBar from './DateProgressBar'
import { getISOStringFromCalendarDate, quarterOfYear } from '../../utilities/helpers'
import endpoints from '../../API/endpoints'
import callAPI from '../../API/callSecureAPI'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import Link from '@mui/material/Link'
import { useAuth0 } from '../../contexts/auth0-context'
import store from '../../redux/store'
import _ from 'underscore'
import { connect, useSelector } from 'react-redux'
import Chip from '@mui/material/Chip'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import CreateButton from '../Buttons/CreateButton'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { ToggleButton, ToggleButtonGroup } from '@mui/lab'
import EventIcon from '@mui/icons-material/Event'
import GridViewIcon from '@mui/icons-material/GridView'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { setAutoOpenPanel } from '../../redux/actions'
import { Portal } from 'react-overlays'
import { Radio, RadioGroup } from '@mui/material'
import { useActivityStatus } from '../../shared/hooks/useActivityStatus'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

let columns = [
    { id: 'title', label: 'Title' },
    { id: 'type', label: 'Type' },
    // { id: 'dueDate', label: 'Due Date' },
    { id: 'status', label: 'Status' },
]

let categories = [
    '---',
    'Journal Pub',
    'Congress Pub',
    'HCEI 502(a)',
    'PIE',
    'Field Tools',
    'Label',
    'Dossier',
    'Abstract',
    'Poster',
    'Manuscript',
]

const MAX_PRIMARY_EVIDENCE = 3

function mapDispatchToProps(dispatch) {
    return {
        setAutoOpenPanel: (body) => dispatch(setAutoOpenPanel(body)),
    }
}

let newLabelMapping = {
    Journal: 'Abstract',
    Congress: 'Poster',
    Manuscript: 'Manuscript',
}

function ConnectedActivityDetailedView({
                                           activity,
                                           forceParentRefresh,
                                           closePanel,
                                           editing,
                                           setEditing,
                                           triggerSave,
                                           triggerCancel,
                                           triggerDelete,
                                           navigationStack,
                                       }) {
    const classes = useStyles()
    const theme = useTheme()

    const snapshotMode = useSelector((state) => (state.strategy ? !!state.strategy.sourceId : false))
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))
    const isDarkMode = useSelector((state) =>
        !state.userRecord ? true : state?.userRecord?.isDarkMode === undefined ? true : state?.userRecord?.isDarkMode,
    )

    const [openModal, setOpenModal] = useState(false)

    const [isLoaded, setIsLoaded] = useState(false)
    const { isAuthenticated, getTokenSilently } = useAuth0()

    const [statusValue, setStatusValue] = useState('')
    const [newStatusValue, setNewStatusValue] = useState('')

    const [value, setValue] = useState(0)
    const [forceRefresh, setForceRefresh] = useState(false)

    // const [editing, setEditing] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openErrorModal, setOpenErrorModal] = useState(false)

    const [activityTitle, setActivityTitle] = useState('')
    const [newActivityTitle, setNewActivityTitle] = useState('')

    const [budget, setBudget] = useState(0)
    const [newBudget, setNewBudget] = useState(0)

    const [linkedValueMessages, setLinkedValueMessages] = useState([])
    const [linkedEvidence, setLinkedEvidence] = useState([])

    const [category, setCategory] = useState('---')
    const [newCategory, setNewCategory] = useState('---')

    const [isFunction, setFunction] = useState('---')
    const [newFunction, setNewFunction] = useState('---')

    const [intendedUse, setIntendedUse] = useState({})
    const [newIntendedUse, setNewIntendedUse] = useState({})

    const [isPublication, setIsPublication] = useState(false)
    const [newIsPublication, setNewIsPublication] = useState(false)

    const [publication, setPublication] = useState({})
    const [newPublication, setNewPublication] = useState({})

    const [funded, setFunded] = useState(false)
    const [newFunded, setNewFunded] = useState(false)

    const [startDate, setStartDate] = useState(null)
    const [newStartDate, setNewStartDate] = useState(null)

    const [endDate, setEndDate] = useState('')
    const [newEndDate, setNewEndDate] = useState('')

    const [description, setDescription] = useState('')
    const [newDescription, setNewDescription] = useState('')

    const [studyNumber, setStudyNumber] = useState('')
    const [newStudyNumber, setNewStudyNumber] = useState('')

    const [dataSource, setDataSource] = useState('')
    const [newDataSource, setNewDataSource] = useState('')

    const [isVendorStudy, setIsVendorStudy] = useState(false)
    const [newIsVendorStudy, setNewIsVendorStudy] = useState(false)

    const [isComplete, setIsComplete] = useState(false)
    const [newIsComplete, setNewIsComplete] = useState(false)

    const [isInitiated, setIsInitiated] = useState(false)
    const [newIsInitiated, setNewIsInitiated] = useState(false)

    const [isOnHold, setIsOnHold] = useState(false)
    const [newIsOnHold, setNewIsOnHold] = useState(false)

    const [isCancelled, setIsCancelled] = useState(false)
    const [newIsCancelled, setNewIsCancelled] = useState(false)

    const [isPlanned, setIsPlanned] = useState(false)
    const [newIsPlanned, setNewIsPlanned] = useState(false)

    const [vendorDetails, setVendorDetails] = useState('')
    const [newVendorDetails, setNewVendorDetails] = useState('')

    const [link, setLink] = useState('')
    const [newLink, setNewLink] = useState('')

    const [settings, setSettings] = useState({})

    const [openDependencyModal, setOpenDependencyModal] = useState(false)
    const [checkedActivitiesMap, setCheckedActivitiesMap] = useState({})
    const [allOtherActivities, setAllOtherActivities] = useState([])
    const [dependencyList, setDependencyList] = useState([])

    const [deliverablesMap, setDeliverablesMap] = useState({})
    const [newDeliverablesMap, setNewDeliverablesMap] = useState({})
    const [openDeliverableDeleteModal, setOpenDeliverableDeleteModal] = useState(false)
    const [deleteDeliverableId, setDeleteDeliverableId] = useState('')

    const activitiesDisplayName = store.getState().settings?.displayNames?.activities || 'Loading'

    const [quarterOrMonth, setQuarterOrMonth] = useState('month')

    const [startDateCalendar, setStartDateCalendar] = useState(null)
    const [newStartDateCalendar, setNewStartDateCalendar] = useState(null)

    const [endDateCalendar, setEndDateCalendar] = useState(null)
    const [newEndDateCalendar, setNewEndDateCalendar] = useState(null)

    const [startError, setStartError] = React.useState(null)
    const [endError, setEndError] = React.useState(null)

    const startErrorMessage = React.useMemo(() => {
        switch (startError) {
            case 'invalidDate': {
                return 'Invalid date'
            }
            default: {
                return ''
            }
        }
    }, [startError])

    const endErrorMessage = React.useMemo(() => {
        switch (endError) {
            case 'minDate': {
                return 'End is before Start'
            }
            case 'invalidDate': {
                return 'Invalid date'
            }
            default: {
                return ''
            }
        }
    }, [endError])

    useEffect(() => {
        if (forceRefresh) {
            setForceRefresh(false)
        }

        if (!isAuthenticated || !activity) {
            return
        }
        getActivityData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity, forceRefresh, isAuthenticated])

    useEffect(() => {
        if (triggerSave === 0) {
            return
        }
        saveActivity()
    }, [triggerSave])

    useEffect(() => {
        if (triggerCancel === 0) {
            return
        }
        handleCancel()
    }, [triggerCancel])

    useEffect(() => {
        if (triggerDelete === 0) {
            return
        }
        handleDeleteModalOpen()
    }, [triggerDelete])

    async function getActivityData() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        updateState(activity)

        let incomingSettings = await callAPI(
            endpoints.getSettingsByOrganizationId.url + store.getState().strategy.organizationId,
            endpoints.getSettingsByOrganizationId.method,
            token,
        )
        if (incomingSettings.success) {
            setSettings(incomingSettings.data)
        }

        setIsLoaded(true)

        let linkedVMData = await callAPI(endpoints.getLinkedVMs.url, endpoints.getLinkedVMs.method, token, {
            listOfValueMessages: activity.linkedValueMessages,
        })
        setLinkedValueMessages(linkedVMData.success ? linkedVMData.data : [])

        let dependencies = await callAPI(
            endpoints.getLinkedActivities.url,
            endpoints.getLinkedActivities.method,
            token,
            { listOfActivities: activity.activityDependencies },
        )

        if (dependencies.success) {
            setDependencyList(dependencies.data)
        }

        let incomingActivities = await callAPI(
            endpoints.getActivitiesForTable.url + store.getState().strategy._id,
            endpoints.getActivitiesForTable.method,
            token,
        )
        if (incomingActivities.success) {
            let allOtherActs = incomingActivities.data.filter((x) => x._id !== activity._id)
            setAllOtherActivities(allOtherActs)
            let tempMap = {}
            allOtherActs.map(
                (act) =>
                    (tempMap[act._id] =
                        activity.activityDependencies && activity.activityDependencies.includes(act._id)),
            )
            setCheckedActivitiesMap(tempMap)
        }

        let linkedEvidenceData = await callAPI(
            endpoints.getLinkedEvidence.url,
            endpoints.getLinkedEvidence.method,
            token,
            { listOfEvidence: activity.linkedEvidence },
        )
        if (linkedEvidenceData.success) {
            let sortedEvidence = linkedEvidenceData.data.sort((a, b) => a.name.localeCompare(b.name))
            let starredElements = []

            for (let i = 0; i < sortedEvidence.length; i++) {
                if (activity.primaryEvidence && activity.primaryEvidence.includes(sortedEvidence[i]._id)) {
                    let el = sortedEvidence[i]
                    sortedEvidence.splice(i, 1)
                    i--
                    starredElements.push(el)
                }
            }
            starredElements = starredElements.sort((a, b) => a.name.localeCompare(b.name))
            starredElements = starredElements.concat(sortedEvidence)
            setLinkedEvidence(starredElements)
        } else {
            setLinkedEvidence([])
        }
    }

    function updateState(activity) {
        setActivityTitle(activity.title)
        setNewActivityTitle(activity.title)
        setBudget(activity.budget)
        setNewBudget(activity.budget)
        setCategory(activity.category)
        setNewCategory(activity.category)
        setFunction(activity.function)
        setNewFunction(activity.function)
        setIntendedUse(activity.intendedUse)
        setNewIntendedUse(activity.intendedUse)
        setFunded(activity.funded)
        setNewFunded(activity.funded)
        setDescription(activity.description)
        setNewDescription(activity.description)
        setIsPublication(activity.publication?.isPublication)
        setNewIsPublication(activity.publication?.isPublication)
        setPublication(activity.publication)
        setNewPublication(activity.publication)
        setStudyNumber(activity.studyNumber)
        setNewStudyNumber(activity.studyNumber)
        setDataSource(activity.dataSource)
        setNewDataSource(activity.dataSource)
        setIsVendorStudy(activity.vendor)
        setNewIsVendorStudy(activity.vendor)
        setVendorDetails(activity.vendorDetails)
        setNewVendorDetails(activity.vendorDetails)
        getDeliverables(activity.deliverables)
        setIsComplete(activity.isComplete)
        setNewIsComplete(activity.isComplete)
        setIsInitiated(activity.isInitiated)
        setNewIsInitiated(activity.isInitiated)
        setIsPlanned(activity.isPlanned)
        setNewIsPlanned(activity.isPlanned)
        setIsOnHold(activity.isOnHold)
        setNewIsOnHold(activity.isOnHold)
        setIsCancelled(activity.isCancelled)
        setNewIsCancelled(activity.isCancelled)
        setNewStatusValue(activity.status)
        setStatusValue(activity.status)

        let formattedLink = function() {
            if (!activity.link || activity.link.length === 0) {
                return ''
            } else {
                return activity.link.includes('http://') || activity.link.includes('https://')
                    ? activity.link
                    : 'http://' + activity.link
            }
        }
        setLink(formattedLink)
        setNewLink(formattedLink)

        if (activity.startDate) {
            if (new Date(activity.startDate).getMinutes() === 1) {
                // specific date set
                setStartDateCalendar(dayjs(new Date(activity.startDate)))
                setNewStartDateCalendar(dayjs(new Date(activity.startDate)))
            } else {
                // quarter is set
                setQuarterOrMonth('quarter')
                setStartDate(activity.startDate)
                setNewStartDate(activity.startDate)
            }
        }
        if (activity.endDate) {
            if (new Date(activity.endDate).getMinutes() === 1) {
                // specific date set
                setEndDateCalendar(dayjs(new Date(activity.endDate)))
                setNewEndDateCalendar(dayjs(new Date(activity.endDate)))
            } else {
                // quarter is set
                setQuarterOrMonth('quarter')
                setEndDate(activity.endDate)
                setNewEndDate(activity.endDate)
            }
        }
    }

    async function getDeliverables(deliv) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingDeliverables = await callAPI(
            endpoints.getDeliverableList.url,
            endpoints.getDeliverableList.method,
            token,
            {
                listOfDeliverables: deliv,
            },
        )
        if (incomingDeliverables.success) {
            let tempMap = {}
            let tempMap2 = {}
            for (let deliv of [...incomingDeliverables.data]) {
                tempMap[deliv._id] = deliv
                tempMap2[deliv._id] = deliv
            }
            setDeliverablesMap({ ...tempMap })
            setNewDeliverablesMap({ ...tempMap2 })
        }
    }

    async function newDeliverable(incomingDeliverable) {
        if (isAuthenticated) {
            let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
            await callAPI(
                endpoints.createDeliverable.url + activity._id,
                endpoints.createDeliverable.method,
                token,
                incomingDeliverable,
            )
        }
        setEditing(false)
        // getActivityData()
        forceParentRefresh(true)
    }

    async function addLinkedEvidence(checkedEvidence) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        // Find the differences between the checked values and current values
        let toDelete = activity.linkedEvidence.filter((x) => !checkedEvidence.includes(x))
        toDelete = toDelete.filter((x) => x.length > 5)
        let toAdd = checkedEvidence.filter((x) => !activity.linkedEvidence.includes(x))
        toAdd = toAdd.filter((x) => x.length > 5)

        // Trigger appropriate linking function for each to enable two-way binding
        // for...of loops execute in sequence. Using 'await' prevents DB versioning  conflicts
        for (let evidenceId of toAdd) {
            await callAPI(
                endpoints.linkEvidenceToActivity.url + activity._id + '/' + evidenceId,
                endpoints.linkEvidenceToActivity.method,
                token,
            )
        }
        for (let evidenceId of toDelete) {
            await callAPI(
                endpoints.deleteLinkEvidenceToActivity.url + activity._id + '/' + evidenceId,
                endpoints.deleteLinkEvidenceToActivity.method,
                token,
            )
        }
        setForceRefresh(true)
        forceParentRefresh(true)
    }

    async function addLinkedValueMessages(checkedVMs) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        // Find the differences between the checked values and current values
        let toDelete = activity.linkedValueMessages.filter((x) => !checkedVMs.includes(x))
        toDelete = toDelete.filter((x) => x.length > 5)
        let toAdd = checkedVMs.filter((x) => !activity.linkedValueMessages.includes(x))
        toAdd = toAdd.filter((x) => x.length > 5)

        // Trigger appropriate linking function for each to enable two-way binding
        // for...of loops execute in sequence. Using 'await' prevents DB versioning  conflicts
        for (let valueMessageId of toAdd) {
            await callAPI(
                endpoints.linkVMToActivity.url + activity._id + '/' + valueMessageId,
                endpoints.linkVMToActivity.method,
                token,
            )
        }
        for (let valueMessageId of toDelete) {
            await callAPI(
                endpoints.deleteLinkVMToActivity.url + activity._id + '/' + valueMessageId,
                endpoints.deleteLinkVMToActivity.method,
                token,
            )
        }
        setForceRefresh(true)
        forceParentRefresh(true)
    }

    async function saveActivity() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        if (newStartDate && newEndDate && new Date(newStartDate) > new Date(newEndDate)) {
            setOpenErrorModal(true)
            return
        }
        if (
            newStartDateCalendar &&
            newEndDateCalendar &&
            new Date(newStartDateCalendar) > new Date(newEndDateCalendar)
        ) {
            setOpenErrorModal(true)
            return
        }
        setEditing(!editing)

        let tempMap = { ...newDeliverablesMap }
        for (let deliv of Object.keys(newDeliverablesMap)) {
            if (_.isEqual(newDeliverablesMap[deliv], deliverablesMap[deliv])) {
                delete tempMap[deliv]
            }
        }

        await callAPI(endpoints.updateDeliverablesList.url, endpoints.updateDeliverablesList.method, token, {
            deliverablesMap: tempMap,
        })
        let newActivity = {
            studyNumber: newStudyNumber,
            title: newActivityTitle,
            description: newDescription,
            dataSource: newDataSource,
            budget: newBudget ? newBudget : 0,
            category: newCategory,
            function: newFunction,
            intendedUse: newIntendedUse,
            funded: newFunded,
            startDate: quarterOrMonth === 'quarter' ? newStartDate : getISOStringFromCalendarDate(newStartDateCalendar),
            endDate: quarterOrMonth === 'quarter' ? newEndDate : getISOStringFromCalendarDate(newEndDateCalendar),
            isComplete: newIsComplete,
            isInitiated: newIsInitiated,
            isPlanned: newIsPlanned,
            isOnHold: newIsOnHold,
            isCancelled: newIsCancelled,
            status: newStatusValue,
            strategyId: activity.strategyId,
            organizationId: activity.organizationId,
            linkedEvidence: activity.linkedEvidence,
            linkedValueMessages: activity.linkedValueMessages,
            primaryEvidence: activity.primaryEvidence,
            publication: {
                isPublication: newIsPublication,
                Journal: newPublication.Journal,
                Congress: newPublication.Congress,
                Manuscript: newPublication.Manuscript,
            },
            vendor: newIsVendorStudy,
            vendorDetails: newVendorDetails,
            activityDependencies: dependencyList,
            deliverables: activity.deliverables,
            link: newLink,
            order: activity.order,
        }

        callAPI(endpoints.updateActivity.url + activity._id, endpoints.updateActivity.method, token, newActivity)
        setActivityTitle(newActivityTitle)
        setBudget(newBudget)
        setCategory(newCategory)
        setFunction(newFunction)
        setIntendedUse(newIntendedUse)
        setFunded(newFunded)
        setDescription(newDescription)
        setPublication(newPublication)
        setIsPublication(newIsPublication)
        setStudyNumber(newStudyNumber)
        setDataSource(newDataSource)
        setIsVendorStudy(newIsVendorStudy)
        setVendorDetails(newVendorDetails)
        setDeliverablesMap(newDeliverablesMap)
        setIsComplete(newIsComplete)
        setIsInitiated(newIsInitiated)
        setIsPlanned(newIsPlanned)
        setIsOnHold(newIsOnHold)
        setIsCancelled(newIsCancelled)
        setLink(newLink)
        setStartError(null)
        setEndError(null)

        if (quarterOrMonth === 'quarter') {
            setStartDateCalendar(null)
            setNewStartDateCalendar(null)
            setEndDateCalendar(null)
            setNewEndDateCalendar(null)
            setStartDate(newStartDate)
            setEndDate(newEndDate)
        } else {
            setStartDate('')
            setNewStartDate('')
            setEndDate('')
            setNewEndDate('')
            setStartDateCalendar(newStartDateCalendar)
            setEndDateCalendar(newEndDateCalendar)
        }
        forceParentRefresh(true)
    }

    async function deleteTheActivity() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        setOpenDeleteModal(false)
        await callAPI(endpoints.deleteActivity.url + activity._id, endpoints.deleteActivity.method, token)
        forceParentRefresh(true)
        closePanel()
    }

    function handleCancel() {
        setNewActivityTitle(activityTitle)
        setNewBudget(budget)
        setNewCategory(category)
        setNewFunction(isFunction)
        setNewIntendedUse(intendedUse)
        setNewFunded(funded)
        setNewStartDate(startDate)
        setNewEndDate(endDate)
        setNewDescription(description)
        setEditing(false)
        setNewIsPublication(isPublication)
        setNewPublication(publication)
        setStudyNumber(studyNumber)
        setDataSource(dataSource)
        setIsVendorStudy(isVendorStudy)
        setVendorDetails(vendorDetails)
        setNewDeliverablesMap({ ...deliverablesMap })
        setNewIsComplete(isComplete)
        setNewIsInitiated(isInitiated)
        setNewIsPlanned(isPlanned)
        setNewIsOnHold(isOnHold)
        setNewIsCancelled(isCancelled)
        setNewStatusValue(statusValue)
        let formattedLink = function() {
            if (link.length === 0) {
                return ''
            } else {
                return link.includes('http://') || link.includes('https://') ? link : 'http://' + link
            }
        }
        setNewLink(formattedLink)
        if (startDate || endDate) {
            setQuarterOrMonth('quarter')
        } else if (startDateCalendar || endDateCalendar) {
            setQuarterOrMonth('month')
        }
        newStartDate ? setNewStartDate(startDate) : setNewStartDateCalendar(startDateCalendar)
        newEndDate ? setNewEndDate(endDate) : setNewEndDateCalendar(endDateCalendar)
        setStartError(null)
        setEndError(null)
    }

    const handleActivityCheckboxes = (event) => {
        setNewIntendedUse({
            ...newIntendedUse,
            [event.target.name]: event.target.checked,
        })
    }

    const handlePublicationCheckboxes = (event) => {
        setNewPublication({
            ...newPublication,
            [event.target.name]: event.target.checked,
        })
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleChangeIndex = (index) => {
        setValue(index)
    }

    const linkButtonTabIndexMapping = {
        0: 'vm',
        1: 'evidence',
    }

    function handleDeleteModalClose() {
        setOpenDeleteModal(false)
    }

    function handleDeleteModalOpen() {
        setOpenDeleteModal(true)
    }

    function handleCloseErrorModal() {
        setOpenErrorModal(false)
    }

    async function updateDependencies() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let checkboxes = document.querySelectorAll('input[type=checkbox]')

        function getChecked() {
            var checked = []
            for (var i = 0; i < checkboxes.length; i++) {
                var checkbox = checkboxes[i]
                if (checkbox.checked) checked.push(checkbox.value)
            }
            return checked
        }

        var checked = getChecked()
        // filter out non-Activity._id checkboxes
        checked = checked.filter((x) => x.length > 5)
        let newActivity = {
            studyNumber: newStudyNumber,
            title: newActivityTitle,
            description: newDescription,
            dataSource: newDataSource,
            budget: newBudget ? newBudget : 0,
            category: newCategory,
            function: newFunction,
            intendedUse: newIntendedUse,
            funded: newFunded,
            startDate: quarterOrMonth === 'quarter' ? newStartDate : getISOStringFromCalendarDate(newStartDateCalendar),
            endDate: quarterOrMonth === 'quarter' ? newEndDate : getISOStringFromCalendarDate(newEndDateCalendar),
            strategyId: activity.strategyId,
            organizationId: activity.organizationId,
            linkedEvidence: activity.linkedEvidence,
            linkedValueMessages: activity.linkedValueMessages,
            publication: {
                isPublication: newIsPublication,
                Journal: newPublication.Journal,
                Congress: newPublication.Congress,
                Manuscript: newPublication.Manuscript,
            },
            vendor: newIsVendorStudy,
            vendorDetails: newVendorDetails,
            activityDependencies: checked,
            deliverables: activity.deliverables,
            isComplete: newIsComplete,
            isInitiated: newIsInitiated,
            isPlanned: newIsPlanned,
            isOnHold: newIsOnHold,
            isCancelled: newIsCancelled,
            status: newStatusValue,
            link: '',
            order: activity.order,
        }

        await callAPI(endpoints.updateActivity.url + activity._id, endpoints.updateActivity.method, token, newActivity)
        setOpenDependencyModal(false)
        let dependencies = await callAPI(
            endpoints.getLinkedActivities.url,
            endpoints.getLinkedActivities.method,
            token,
            { listOfActivities: checked },
        )

        if (dependencies.success) {
            setDependencyList(dependencies.data)
        }
        let incomingActivities = await callAPI(
            endpoints.getActivitiesForTable.url + store.getState().strategy._id,
            endpoints.getActivitiesForTable.method,
            token,
        )
        if (incomingActivities.success) {
            let allOtherActs = incomingActivities.data.filter((x) => x._id !== activity._id)
            setAllOtherActivities(allOtherActs)
            let tempMap = {}
            allOtherActs.map((act) => (tempMap[act._id] = checked && checked.includes(act._id)))
            setCheckedActivitiesMap(tempMap)
        }
        forceParentRefresh(true)
    }

    async function addPrimaryEvidence(evidenceId) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        if (activity.primaryEvidence.length >= MAX_PRIMARY_EVIDENCE) {
            setOpenModal(true)
            return
        }
        await callAPI(
            endpoints.addPrimaryEvidenceToActivity.url + activity._id + '/' + evidenceId,
            endpoints.addPrimaryEvidenceToActivity.method,
            token,
        )
        setForceRefresh(true)
        forceParentRefresh(true)
    }

    async function removePrimaryEvidence(evidenceId) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(
            endpoints.removePrimaryEvidenceToActivity.url + activity._id + '/' + evidenceId,
            endpoints.removePrimaryEvidenceToActivity.method,
            token,
        )
        setForceRefresh(true)
        forceParentRefresh(true)
    }

    async function deleteDeliverable(id) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        if (!id.length) {
            return
        }
        await callAPI(
            endpoints.deleteDeliverableById.url + id + '/' + activity._id,
            endpoints.deleteDeliverableById.method,
            token,
        )
        let tempMap = { ...newDeliverablesMap }
        delete tempMap[id]
        let tempOldMap = { ...deliverablesMap }
        delete tempOldMap[id]
        setNewDeliverablesMap(tempMap)
        setDeliverablesMap(tempOldMap)
        setOpenDeliverableDeleteModal(false)
    }

    function handleQuarterOrMonthToggle(event, quarterOrMonth) {
        if (quarterOrMonth !== null) {
            setQuarterOrMonth(quarterOrMonth)
        }
        setNewStartDateCalendar(startDateCalendar)
        setNewEndDateCalendar(endDateCalendar)
        setNewStartDate(startDate)
        setNewEndDate(endDate)
    }

    const CalendarContainer = ({ children }) => {
        const el = document.getElementById('calendar-portal')

        return <Portal container={el}>{children}</Portal>
    }

    function addToStack() {
        let arr = Array.from(navigationStack)
        arr.push({
            path: '/activities',
            data: activity,
        })
        return arr
    }

    const { activityStatusDetails, handleGetActivityStatusDetails } = useActivityStatus({ activityStatus: newStatusValue });

    return (
        <Fade in={isLoaded} timeout={800}>
            <Grid
                container
                direction='column'
                item
                sx={{ m: 0, backgroundColor: isDarkMode ? '#363943' : 'white' }}
                spacing={2}>
                <Dialog
                    open={openDeleteModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleDeleteModalClose}
                    aria-labelledby='alert-dialog-slide-title6'
                    aria-describedby='alert-dialog-slide-description6'>
                    <DialogTitle id='alert-dialog-slide-title6'>
                        <Typography variant='h4'>{`Are you sure you want to delete this Activity?`}</Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleDeleteModalClose} sx={{ mb: 1 }} variant='outlined-sm-button'>
                            CANCEL
                        </Button>
                        <Button onClick={deleteTheActivity} sx={{ mb: 1, mr: 1 }} variant='contained-sm-button'>
                            DELETE
                        </Button>
                    </DialogActions>
                </Dialog>

                {editing ? (
                    <Grid
                        container
                        item
                        direction='column'
                        spacing={4}
                        sx={{
                            width: '100%',
                            ml: 0,
                            pl: 2,
                            pr: 3,
                            pb: 4,
                            borderBottomLeftRadius: '24px',
                            borderBottomRightRadius: '24px',
                            backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6',
                        }}>
                        <Grid container item justifyContent='space-between'>
                            <Grid container item xs={12} direction='row' spacing={2} sx={{ ml: -5, mt: 0 }}>
                                <Grid container xs={5} item direction='column'>
                                    <Grid item sx={{ mb: 1 }}>
                                        <Typography variant="title2"><strong>Study Number</strong></Typography>
                                    </Grid> 
                                    <Grid item xs>
                                        <TextField
                                            id='study-number'
                                            onFocus={(event) => {
                                                event.target.setAttribute('autocomplete', 'off')
                                            }}
                                            placeholder={studyNumber}
                                            value={newStudyNumber}
                                            size='small'
                                            onChange={(e) => setNewStudyNumber(e.target.value)}
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    borderRadius: '12px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={5} direction='column'>
                                    <Grid item>
                                        <Typography variant="title2"><strong>Status</strong></Typography>
                                    </Grid> 
                                    <Grid item xs>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby='demo-controlled-radio-buttons-group'
                                                name='controlled-radio-buttons-group'
                                                value={newStatusValue}
                                                row>
                                                <FormControlLabel value='Planned' control={<Radio onClick={(e) => {
                                                    if (e.target.value === newStatusValue) {
                                                        setNewStatusValue('')
                                                    } else {
                                                        setNewStatusValue(e.target.value)
                                                    }
                                                }} />} label={handleGetActivityStatusDetails('Planned').name} />
                                                <FormControlLabel value='Initiated' control={<Radio onClick={(e) => {
                                                    if (e.target.value === newStatusValue) {
                                                        setNewStatusValue('')
                                                    } else {
                                                        setNewStatusValue(e.target.value)
                                                    }
                                                }} />} label={handleGetActivityStatusDetails('Initiated').name} />
                                                <FormControlLabel value='On Hold' control={<Radio onClick={(e) => {
                                                    if (e.target.value === newStatusValue) {
                                                        setNewStatusValue('')
                                                    } else {
                                                        setNewStatusValue(e.target.value)
                                                    }
                                                }} />} label={handleGetActivityStatusDetails('On Hold').name} />

                                                <FormControlLabel value='Cancelled' control={<Radio onClick={(e) => {
                                                    if (e.target.value === newStatusValue) {
                                                        setNewStatusValue('')
                                                    } else {
                                                        setNewStatusValue(e.target.value)
                                                    }
                                                }} />} label={handleGetActivityStatusDetails('Cancelled').name} />

                                                <FormControlLabel value='Completed' control={<Radio onClick={(e) => {
                                                    if (e.target.value === newStatusValue) {
                                                        setNewStatusValue('')
                                                    } else {
                                                        setNewStatusValue(e.target.value)
                                                    }
                                                }} />} label={handleGetActivityStatusDetails('Completed').name} />

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} sx={{ ml: -3 }}>
                                <Grid container item direction='column'>
                                    <Grid item sx={{ mt: 1.5, mb: 1 }}>
                                        <Typography variant='title2'>Time Frame</Typography>
                                    </Grid>
                                    <Grid container item spacing={2}>
                                        <Grid item xs='auto'>
                                            <ToggleButtonGroup
                                                value={quarterOrMonth}
                                                exclusive
                                                onChange={handleQuarterOrMonthToggle}
                                                aria-label='Quarters or Months'
                                                color='primary'>
                                                <ToggleButton value='month' aria-label='month'>
                                                    <Tooltip title={'Specific Date'}>
                                                        <EventIcon />
                                                    </Tooltip>
                                                </ToggleButton>
                                                <ToggleButton value='quarter' aria-label='quarter'>
                                                    <Tooltip title={'Quarterly Estimate'}>
                                                        <GridViewIcon />
                                                    </Tooltip>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                        {quarterOrMonth === 'quarter' ? (
                                            <QuarterDatePicker
                                                stacked={false}
                                                oldStart={startDate}
                                                oldEnd={endDate}
                                                start={(date) => setNewStartDate(date)}
                                                end={(date) => setNewEndDate(date)}
                                            />
                                        ) : (
                                            <Grid container item>
                                                <Grid item sx={{ mb: 2 }}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <MUIDatePicker
                                                            value={newStartDateCalendar}
                                                            onChange={(newValue) => setNewStartDateCalendar(newValue)}
                                                            label='Start Date'
                                                            onError={(newError) => setStartError(newError)}
                                                            slotProps={{
                                                                textField: {
                                                                    helperText: startErrorMessage,
                                                                },
                                                            }}
                                                            sx={{
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    borderRadius: '12px',
                                                                    marginRight: '5px'
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <MUIDatePicker
                                                            value={newEndDateCalendar}
                                                            onChange={(newValue) => {
                                                                setNewEndDateCalendar(newValue)
                                                            }}
                                                            minDate={newStartDateCalendar ? newStartDateCalendar : null}
                                                            label='End Date'
                                                            onError={(newError) => setEndError(newError)}
                                                            slotProps={{
                                                                textField: {
                                                                    helperText: endErrorMessage,
                                                                },
                                                            }}
                                                            sx={{
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    borderRadius: '12px',
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction='column' sx={{ mb: 2 }} spacing={4}>
                            <Grid item sx={{ mb: -4, ml: -3 }}>
                                <Typography variant="title2"><strong>Title</strong></Typography>
                            </Grid> 
                            <Grid item sx={{ ml: -3 }}>
                                <TextField
                                    id='edit-activity'
                                    multiline
                                    maxRows={4}
                                    placeholder={activityTitle}
                                    value={newActivityTitle}
                                    variant='filled'
                                    onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off')
                                    }}
                                    onChange={(e) => setNewActivityTitle(e.target.value)}
                                    fullWidth
                                    sx={{
                                        '.MuiInputBase-root': {
                                            borderTopLeftRadius: '12px',
                                            borderTopRightRadius: '12px',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{ mb: -3, ml: -3 }}>
                                <Typography variant="title2"><strong>Objective/Rationale</strong></Typography>
                            </Grid> 
                            <Grid item sx={{ ml: -3 }}>
                                <TextField
                                    id='edit-activity'
                                    multiline
                                    maxRows={4}
                                    variant='outlined'
                                    placeholder={description}
                                    value={newDescription}
                                    onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off')
                                    }}
                                    onChange={(e) => setNewDescription(e.target.value)}
                                    fullWidth
                                    sx={{
                                        '.MuiInputBase-root': {
                                            borderRadius: '12px',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{ mb: -3, ml: -3 }}>
                                <Typography variant="title2"><strong>Link</strong></Typography>
                            </Grid> 
                            <Grid item sx={{ ml: -3, mb: -2 }}>
                                <TextField
                                    id='edit-link'
                                    placeholder={link}
                                    value={newLink}
                                    variant='outlined'
                                    onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off')
                                    }}
                                    onChange={(e) => setNewLink(e.target.value)}
                                    fullWidth
                                    sx={{
                                        '.MuiInputBase-root': {
                                            borderRadius: '12px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item wrap='nowrap' spacing={2}>
                            <Grid container direction='column'>
                                <Grid item sx={{ mb: 1, ml: -1, mt: 2 }}>
                                    <Typography variant="title2"><strong>Budget</strong></Typography>
                                </Grid> 
                                <Grid item xs='auto' sx={{ ml: -1 }}>
                                    <TextField
                                        id='edit-budget'
                                        style={{ flex: 1 }}
                                        value={newBudget}
                                        placeholder={budget}
                                        type='number'
                                        InputProps={{
                                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                                        }}
                                        onFocus={(event) => {
                                            event.target.setAttribute('autocomplete', 'off')
                                        }}
                                        onChange={(e) => setNewBudget(Number.parseInt(e.target.value))}
                                        fullWidth
                                        sx={{
                                            '.MuiInputBase-root': {
                                                borderRadius: '12px',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction='column'>
                                <Grid item sx={{ mb: 1 }}>
                                    <Typography variant="title2"><strong>Category</strong></Typography>
                                </Grid> 
                                <Grid container item xs>
                                    <FormControl style={{ flex: 1 }}>
                                        <Select
                                            inputProps={{
                                                id: 'category-select',
                                            }}
                                            value={newCategory}
                                            onChange={(e) => setNewCategory(e.target.value)}
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderRadius: '12px',
                                                },
                                            }}>
                                            <MenuItem value={'---'}>---</MenuItem>
                                            {settings.activityCategories.sort().map((category, i) => (
                                                <MenuItem value={category} key={category + i}>
                                                    {category}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item direction='column'>
                                <Grid item sx={{ mb: 1 }}>
                                    <Typography variant="title2"><strong>Function</strong></Typography>
                                </Grid> 
                                <Grid container item xs>
                                    <FormControl style={{ flex: 1 }}>
                                        <Select
                                            inputProps={{
                                                id: 'function-select',
                                            }}
                                            value={newFunction}
                                            onChange={(e) => setNewFunction(e.target.value)}
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderRadius: '12px',
                                                },
                                            }}>
                                            <MenuItem value={'---'}>---</MenuItem>
                                            {settings.activityFunctions.map((functions, i) => (
                                                <MenuItem value={functions} key={functions + i}>
                                                    {functions}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction='column'>
                            <Grid item sx={{ mb: 1, ml: -3 }}>
                                <Typography variant="title2"><strong>Data Source</strong></Typography>
                            </Grid> 
                            <Grid item sx={{ ml: -3, mr: 0 }}>
                                <TextField
                                    id='data-source'
                                    multiline
                                    fullWidth
                                    variant='outlined'
                                    onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off')
                                    }}
                                    value={newDataSource}
                                    onChange={(e) => setNewDataSource(e.target.value)}
                                    sx={{
                                        '.MuiInputBase-root': {
                                            borderRadius: '12px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2} justifyContent='space-between'>
                            <Grid container item direction='column' xs={2}>
                                <Grid item sx={{ ml: -3 }}>
                                    <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={newIsPublication}
                                                    onChange={(e) => {
                                                        setNewIsPublication(e.target.checked)
                                                        if (!e.target.checked) {
                                                            setNewPublication({
                                                                isPublication: false,
                                                                Journal: false,
                                                                Congress: false,
                                                                Manuscript: false,
                                                            })
                                                        }
                                                    }}
                                                    name='checkedB'
                                                />
                                            }
                                            label={<Typography variant='title2'>Publication</Typography>}
                                        />
                                    </FormControl>
                                </Grid>
                                {newIsPublication && (
                                    <Grid item sx={{ ml: -3 }}>
                                        <FormGroup row>
                                            {Object.keys(newPublication).map((pub, i) => {
                                                if (pub !== 'isPublication') {
                                                    return (
                                                        <FormControlLabel
                                                            key={pub + i}
                                                            control={
                                                                <WhiteOutlineCheckbox
                                                                    checked={newPublication[pub]}
                                                                    onChange={handlePublicationCheckboxes}
                                                                    color='primary'
                                                                    name={pub}
                                                                />
                                                            }
                                                            label={newLabelMapping[pub]}
                                                        />
                                                    )
                                                } else {
                                                    return <></>
                                                }
                                            })}
                                        </FormGroup>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs='auto'>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={newFunded}
                                                onChange={(e) => setNewFunded(e.target.checked)}
                                                name='checkedB'
                                            />
                                        }
                                        label={<Typography variant='title2'>Funded</Typography>}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs='auto' direction='column'>
                                <Grid container item alignItems='center' xs='auto'>
                                    <Grid item>
                                        <Typography variant='title2'>Internal</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Switch
                                            checked={newIsVendorStudy}
                                            onChange={(e) => setNewIsVendorStudy(e.target.checked)}
                                            name='isVendor'
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='title2'>Vendor Study</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs='auto'>
                                    {newIsVendorStudy && (
                                        <FormGroup row>
                                            <TextField
                                                id='vendor-details'
                                                label='Vendor Details'
                                                multiline
                                                minRows={2}
                                                placeholder={vendorDetails}
                                                value={newVendorDetails}
                                                onFocus={(event) => {
                                                    event.target.setAttribute('autocomplete', 'off')
                                                }}
                                                onChange={(e) => setNewVendorDetails(e.target.value)}
                                                fullWidth
                                                sx={{
                                                    '.MuiInputBase-root': {
                                                        borderRadius: '12px',
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container item direction='column' xs={12} lg={5} sx={{ ml: 6 }}>
                                <Grid item sx={{ mt: 1, ml: -3 }}>
                                    <Typography variant='h5'>Intended Use</Typography>
                                </Grid>
                                <Grid container item justifyContent='space-between' sx={{ ml: -3 }}>
                                    <FormGroup row>
                                        {settings.activityIntendedUses.map((use) => (                                                                                                                                                                                                                                                                       
                                            <FormControlLabel
                                                control={
                                                    <WhiteOutlineCheckbox
                                                        checked={newIntendedUse[use]}
                                                        onChange={handleActivityCheckboxes}
                                                        color='primary'
                                                        name={use}
                                                    />
                                                }
                                                label={use}
                                            />
                                        ))}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item sx={{ ml: -3 }}>
                            <Button
                                sx={{ width: '250px', height: '52px', borderRadius: '16px' }}
                                color='primary'
                                variant='contained-big-button'
                                onClick={() => setOpenDependencyModal(true)}>
                                Edit Dependencies
                            </Button>
                        </Grid>
                        <Grid container item direction='column' spacing={2}>
                            <Grid container item direction='column' spacing={2} sx={{ ml: -5 }}>
                                <Grid item sx={{ mb: -1 }}>
                                    <Typography variant='title2'>Deliverables</Typography>
                                </Grid>
                                <Grid item>
                                    <CreateButton id='deliverables' createNew={newDeliverable} />
                                </Grid>
                            </Grid>
                            {!_.isEmpty(deliverablesMap) && (
                                <Grid item sx={{ ml: -3, mr: 0 }}>
                                    <TableContainer component={Paper} sx={{ borderRadius: '16px' }}>
                                        <Table>
                                            <TableHead>
                                                {columns.map((column, i) => (
                                                    <TableCell key={column.label + i}>{column.label}</TableCell>
                                                ))}
                                            </TableHead>
                                            <TableBody>
                                                {Object.keys(newDeliverablesMap).map((row, i) => (
                                                    <TableRow className={classes.delRows} key={'deliverableRow' + i}>
                                                        <TableCell>
                                                            <TextField
                                                                id={'deliverableTitle' + i}
                                                                value={
                                                                    newDeliverablesMap[row]
                                                                        ? newDeliverablesMap[row].title
                                                                        : ''
                                                                }
                                                                margin='none'
                                                                fullWidth
                                                                multiline
                                                                style={{ minWidth: '150px' }}
                                                                onChange={(e) => {
                                                                    let tempMap = { ...newDeliverablesMap }
                                                                    let newObj = { ...newDeliverablesMap[row] }
                                                                    newObj.title = e.target.value
                                                                    tempMap[row] = { ...newObj }
                                                                    setNewDeliverablesMap(tempMap)
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl fullWidth className={`${classes.formControl}`}>
                                                                <InputLabel id='pub-type-label'>Type</InputLabel>
                                                                <Select
                                                                    labelId='pub-type'
                                                                    id={'pub-type' + i}
                                                                    value={
                                                                        newDeliverablesMap[row]
                                                                            ? newDeliverablesMap[row].type
                                                                            : ''
                                                                    }
                                                                    onChange={(e) => {
                                                                        let tempMap = { ...newDeliverablesMap }
                                                                        let newObj = {
                                                                            ...newDeliverablesMap[row],
                                                                        }
                                                                        newObj.type = e.target.value
                                                                        tempMap[row] = { ...newObj }
                                                                        setNewDeliverablesMap(tempMap)
                                                                    }}
                                                                    label='Type'>
                                                                    {categories.map((x, i) => (
                                                                        <MenuItem value={x} key={x + i}>
                                                                            {x}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        {/*<TableCell>*/}
                                                        {/*    <DatePicker*/}
                                                        {/*        popperContainer={CalendarContainer}*/}
                                                        {/*        popperPlacement="top-start"*/}
                                                        {/*        selected={*/}
                                                        {/*            newDeliverablesMap[row] &&*/}
                                                        {/*            newDeliverablesMap[row].dueDate*/}
                                                        {/*                ? new Date(newDeliverablesMap[row].dueDate)*/}
                                                        {/*                : null*/}
                                                        {/*        }*/}
                                                        {/*        onChange={(date) => {*/}
                                                        {/*            let tempMap = { ...newDeliverablesMap }*/}
                                                        {/*            let newObj = { ...newDeliverablesMap[row] }*/}
                                                        {/*            newObj.dueDate = date ? new Date(date) : null*/}
                                                        {/*            tempMap[row] = { ...newObj }*/}
                                                        {/*            setNewDeliverablesMap(tempMap)*/}
                                                        {/*        }}*/}
                                                        {/*        dateFormat="yyyy, QQQ"*/}
                                                        {/*        showQuarterYearPicker*/}
                                                        {/*    />*/}
                                                        {/*</TableCell>*/}
                                                        <TableCell>
                                                            <Grid
                                                                component='label'
                                                                container
                                                                item
                                                                xs={12}
                                                                alignItems='center'>
                                                                <FormControlLabel
                                                                    control={
                                                                        <WhiteOutlineCheckbox
                                                                            checked={
                                                                                newDeliverablesMap[row]
                                                                                    ? newDeliverablesMap[row].isComplete
                                                                                    : false
                                                                            }
                                                                            onChange={(e) => {
                                                                                let tempMap = {
                                                                                    ...newDeliverablesMap,
                                                                                }
                                                                                let newObj = {
                                                                                    ...newDeliverablesMap[row],
                                                                                }
                                                                                newObj.isComplete = e.target.checked
                                                                                tempMap[row] = { ...newObj }
                                                                                setNewDeliverablesMap(tempMap)
                                                                            }}
                                                                            color='primary'
                                                                            name='Completed'
                                                                        />
                                                                    }
                                                                    labelPlacement='bottom'
                                                                    label='Completed'
                                                                />
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                            <DeleteForeverIcon
                                                                onClick={() => {
                                                                    setDeleteDeliverableId(row)
                                                                    setOpenDeliverableDeleteModal(true)
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container item direction='column' spacing={2} alignItems='center'>
                        <Accordion defaultExpanded square variant='rounded-bottom'>
                            <AccordionSummary
                                sx={{
                                    backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6',
                                    pl: 3,
                                    pt: 1.5,
                                    pb: 1.5,
                                    borderBottomLeftRadius: '24px',
                                    borderBottomRightRadius: '24px',
                                }}
                                expandIcon={
                                    <IconButton>
                                        <ExpandMoreIcon color='primary' sx={{ mr: 1, mb: 0.5 }} />
                                    </IconButton>
                                }
                                aria-controls='panel1a-content'
                                id='panel1a-header'>
                                <Grid container alignItems='center' spacing={4} wrap='nowrap'>
                                    <Grid container item direction='column' spacing={1}>
                                        <Grid item>
                                            <Typography variant='h5'>Title:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='h2'>{activityTitle}</Typography>
                                        </Grid>
                                    </Grid>
                                    {newStatusValue && (
                                        <Grid item>
                                            <Chip
                                                icon={
                                                    <SvgIcon
                                                        component={activityStatusDetails.icon}
                                                        inheritViewBox
                                                        fontSize='small'
                                                    />
                                                }
                                                label={
                                                    <Typography variant='chip'>{activityStatusDetails.name}</Typography>
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3} sx={{ pl: 1, pb: 2 }}>
                                    {
                                        <Grid container item spacing={1} alignItems='center'>
                                            <Grid item>
                                                <Typography variant='title2'>Study Number:</Typography>
                                            </Grid>
                                            <Grid item>
                                                {studyNumber ? (
                                                    <Typography variant='button'>{studyNumber}</Typography>
                                                ) : (
                                                    <Typography variant='subtitle2'>
                                                        No study number to display
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid container item spacing={2}>
                                        {
                                            <Grid container item direction='column' xs={12} sm={5.5} sx={{ mr: 4.5 }}>
                                                <Grid item>
                                                    <Typography variant='title2'>Objective/Rationale</Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    sx={{
                                                        p: 1,
                                                        mt: 1,
                                                        border: 1,
                                                        borderColor: '#CCCCCC',
                                                        borderRadius: '9px',
                                                    }}
                                                    elevation={1}>
                                                    <Typography variant='subtitle2'>
                                                        {description && description.length > 1
                                                            ? description
                                                            : 'No objective/rationale to display'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid container item xs={12} sm={6}>
                                            <Grid item>
                                                <Typography variant='title2'>Timeline</Typography>
                                            </Grid>
                                            {startDate && endDate ? (
                                                <>
                                                    {startDate && endDate ? (
                                                        <DateProgressBar
                                                            dates={{
                                                                startDate: startDate,
                                                                endDate: endDate,
                                                            }}
                                                        />
                                                    ) : (
                                                        <Grid container item spacing={2}>
                                                            {startDate && (
                                                                <Grid container item>
                                                                    <Grid item>
                                                                        <Typography variant='title2'>Start:</Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant='button'>
                                                                            {new Date(startDate).getFullYear()}, Q
                                                                            {quarterOfYear(new Date(startDate))}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            {startDateCalendar && (
                                                                <Grid container item>
                                                                    <Grid item>
                                                                        <Typography variant='title2'>
                                                                            Start Date:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant='button'>
                                                                            {`${startDateCalendar.format(
                                                                                'MM/DD/YYYY',
                                                                            )}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            {endDate && (
                                                                <Grid container item>
                                                                    <Grid item>
                                                                        <Typography variant='title2'>End:</Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant='body1'>
                                                                            {new Date(endDate).getFullYear()}, Q
                                                                            {quarterOfYear(new Date(endDate))}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            {endDateCalendar && (
                                                                <Grid container item>
                                                                    <Grid item>
                                                                        <Typography variant='title2'>
                                                                            End Date:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant='button'>
                                                                            {`${endDateCalendar.format('MM/DD/YYYY')}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    )}
                                                </>
                                            ) : (
                                                <Grid container xs={12}>
                                                    <Typography variant='subtitle2'>No timeline to display</Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container item>
                                        <Grid item sx={{ borderTop: '1px solid #CCCCCC', width: '99%', mt: 1 }}></Grid>
                                        <Grid item sx={{ pt: 2, width: '100%' }}>
                                            <Typography variant='h5'>Details</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={10.5} sm={5.5} spacing={2} direction='column'>
                                        <Grid container item alignItems='center' spacing={2}>
                                            {
                                                <>
                                                    <Grid container item alignItems='center' spacing={1}>
                                                        <Grid item>
                                                            <Typography variant='title2'>Budget:</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Chip
                                                                label={
                                                                    <Typography variant='chip'>
                                                                        {store.getState().userRecord &&
                                                                        store.getState().userRecord.readonly
                                                                            ? '[redacted]'
                                                                            : `${budget.toLocaleString()}`}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Grid>
                                                        {funded && (
                                                            <Grid item>
                                                                <Chip
                                                                    color='success'
                                                                    label={
                                                                        <Typography
                                                                            variant='chip'
                                                                            style={{ color: 'white' }}>
                                                                            Funded
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                        <Grid
                                            item // Divider separating Budget and Category
                                            sx={{
                                                borderTop: '1px solid #CCCCCC',
                                                width: '98%',
                                                ml: 2,
                                                mt: 2,
                                                mb: -2,
                                            }}></Grid>
                                        <Grid container item spacing={2}>
                                            <Grid item>
                                                <Typography variant='title2'>Category:</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Chip label={<Typography variant='chip'>{category}</Typography>} />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item // Divider separating Category and Function
                                            sx={{
                                                borderTop: '1px solid #CCCCCC',
                                                width: '98%',
                                                ml: 2,
                                                mt: 2,
                                                mb: -2,
                                            }}></Grid>
                                        <Grid container item spacing={2}>
                                            <Grid item>
                                                <Typography variant='title2'>Function:</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Chip label={<Typography variant='chip'>{isFunction}</Typography>} />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item // Divider separating Function and Link
                                            sx={{
                                                borderTop: '1px solid #CCCCCC',
                                                width: '98%',
                                                ml: 2,
                                                mt: 2,
                                                mb: -2,
                                            }}></Grid>
                                        {
                                            <Grid container item spacing={2}>
                                                <Grid item>
                                                    <Typography variant='title2'>Link:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Link
                                                        variant='subtitle2'
                                                        underline='none'
                                                        href={link}
                                                        target='_blank'
                                                        rel='noopener noreferrer'>
                                                        {link.length > 0
                                                            ? link.length > 15
                                                                ? link.slice(0, 55) + '...'
                                                                : link
                                                            : 'No link to display'}
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid
                                            item // Divider separating Link and Data Source
                                            sx={{
                                                borderTop: '1px solid #CCCCCC',
                                                width: '98%',
                                                ml: 2,
                                                mt: 2,
                                                mb: -2,
                                            }}></Grid>
                                        {
                                            <Grid container item spacing={2}>
                                                <Grid item>
                                                    <Typography variant='title2'>Data Source:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='subtitle2'>
                                                        {dataSource ? dataSource : 'No data source to display'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid
                                            item // Divider separating Data Source and Publications when breakpoint is "xs"
                                            sx={{
                                                borderTop: '1px solid #CCCCCC',
                                                width: '98%',
                                                ml: 2,
                                                mt: 2,
                                                mb: -2,
                                                display: { xs: 'block', sm: 'none' },
                                            }}></Grid>
                                    </Grid>
                                    <Grid // Vertical divider separating left and right side of Details
                                        item
                                        sm={0}
                                        sx={{
                                            ml: 4,
                                            mr: -3,
                                            borderLeft: 1,
                                            borderColor: '#CCCCCC',
                                            display: { xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block' },
                                        }}></Grid>
                                    <Grid container item xs={10.5} sm={5.5} spacing={2} direction='column'>
                                        {
                                            <Grid container item xs='auto' direction='column'>
                                                <Grid item>
                                                    <Typography variant='title2'>Publications:</Typography>
                                                </Grid>
                                                <Grid container item xs>
                                                    {Object.keys(publication).filter(
                                                        (pub) => publication[pub] && pub !== 'isPublication',
                                                    ).length > 0 ? (
                                                        <ul>
                                                            {Object.keys(publication).map((pub, i) => {
                                                                if (publication[pub] && pub !== 'isPublication') {
                                                                    return (
                                                                        <li key={'pub' + i}>
                                                                            <Typography variant='body3'>
                                                                                {newLabelMapping[pub]}
                                                                            </Typography>
                                                                        </li>
                                                                    )
                                                                }
                                                                return null
                                                            })}
                                                        </ul>
                                                    ) : (
                                                        <Typography variant='subtitle2'>
                                                            No publications to display
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid
                                            item // Divider separating Publications and Study Details
                                            sx={{
                                                borderTop: '1px solid #CCCCCC',
                                                width: '96.5%',
                                                ml: 2,
                                                mb: -1,
                                                mt: 2,
                                            }}></Grid>
                                        <Grid container item spacing={2} xs='auto'>
                                            <Grid item>
                                                <Typography variant='title2'>Study Details:</Typography>
                                            </Grid>
                                            <Grid container item xs spacing={1} direction='column'>
                                                <Grid item>
                                                    <Chip
                                                        label={
                                                            <Typography variant='chip'>
                                                                {isVendorStudy ? 'Vendor Study' : 'Internal Study'}
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>
                                                {isVendorStudy && (
                                                    <Grid item>
                                                        <Typography variant='body3'>{vendorDetails}</Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item // Divider separating Study Details and Intended Use
                                            sx={{
                                                borderTop: '1px solid #CCCCCC',
                                                width: '96.5%',
                                                ml: 2,
                                                mt: 2,
                                                mb: -2,
                                            }}></Grid>
                                        {Object.keys(intendedUse).some((use) => intendedUse[use]) ? (
                                            <Grid container item xs='auto' direction='column'>
                                                <Grid item>
                                                    <Typography variant='title2'>Intended Use:</Typography>
                                                </Grid>
                                                <Grid container item xs>
                                                    <ul>
                                                        {Object.keys(intendedUse).map((use) => {
                                                            if (intendedUse[use]) {
                                                                return (
                                                                    <li key={use}>
                                                                        <Typography variant='body3'>{use}</Typography>
                                                                    </li>
                                                                )
                                                            }
                                                            return null
                                                        })}
                                                    </ul>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid container item xs='auto' direction='column'>
                                                <Grid item>
                                                    <Typography variant='title2'>Intended Use:</Typography>
                                                </Grid>
                                                <Grid container item xs>
                                                    <Typography variant='subtitle2'>
                                                        No intended uses to display
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid container item>
                                        <Grid
                                            item // Divider separating Details and Dependencies/Deliverables
                                            sx={{ borderTop: '1px solid #CCCCCC', width: '99%', mb: 2 }}></Grid>
                                        <Grid container item>
                                            {
                                                <Grid container item xs={5.5} sx={{ mr: 2.5 }}>
                                                    <Grid item>
                                                        <Typography variant='title2'>Dependencies</Typography>
                                                    </Grid>
                                                    <Grid container item>
                                                        {dependencyList.length > 0 &&
                                                        dependencyList.some((dep) => dep) ? (
                                                            <ul>
                                                                {dependencyList.map((dep, i) => (
                                                                    <li key={'dep' + i}>
                                                                        {dep && dep.title
                                                                            ? dep.title
                                                                            : 'Unnamed Dependency'}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <Typography variant='subtitle2'>
                                                                No dependencies to display
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid
                                                item // Horizontal divider separating Dependencies and Deliverables when breakpoint is "xs"
                                                sx={{
                                                    borderTop: '1px solid #CCCCCC',
                                                    width: '98%',
                                                    mb: 2,
                                                    display: { xs: 'block', sm: 'none' },
                                                }}></Grid>
                                            <Grid
                                                item // Vertical divider separating Dependencies and Deliverables
                                                xs={0}
                                                sx={{
                                                    mr: 3,
                                                    borderLeft: 1,
                                                    borderColor: '#CCCCCC',
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block',
                                                        md: 'block',
                                                        lg: 'block',
                                                        xl: 'block',
                                                    },
                                                }}></Grid>
                                            {
                                                <Grid container item xs={5}>
                                                    <Grid item>
                                                        <Typography variant='title2'>Deliverables</Typography>
                                                    </Grid>
                                                    <Grid container item>
                                                        {Object.keys(deliverablesMap).length > 0 ? (
                                                            <TableContainer
                                                                component={Paper}
                                                                sx={{ borderRadius: '16px', boxShadow: 0, mt: 2 }}>
                                                                <Table size='small'>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            {columns.map((column, i) => (
                                                                                <TableCell
                                                                                    key={'actColumn' + i}
                                                                                    sx={{
                                                                                        backgroundColor: isDarkMode
                                                                                            ? '#1B1C1D'
                                                                                            : '#D3D5DB',
                                                                                    }}>
                                                                                    {column.label}
                                                                                </TableCell>
                                                                            ))}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {Object.keys(deliverablesMap).map((row, i) => (
                                                                            <TableRow
                                                                                key={'actRow' + i}
                                                                                className={classes.delRows}>
                                                                                <TableCell
                                                                                    sx={{
                                                                                        backgroundColor: isDarkMode
                                                                                            ? '#1B1C1D'
                                                                                            : '#D3D5DB',
                                                                                    }}>
                                                                                    {deliverablesMap[row].title}
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    sx={{
                                                                                        backgroundColor: isDarkMode
                                                                                            ? '#1B1C1D'
                                                                                            : '#D3D5DB',
                                                                                    }}>
                                                                                    {deliverablesMap[row].type}
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    sx={{
                                                                                        backgroundColor: isDarkMode
                                                                                            ? '#1B1C1D'
                                                                                            : '#D3D5DB',
                                                                                    }}>
                                                                                    {deliverablesMap[row].status}
                                                                                </TableCell>
                                                                                {/* Uncomment or modify these sections as needed */}
                                                                                {/*<TableCell>
                                                                                        {deliverablesMap[row].dueDate ? (
                                                                                            'Q' +
                                                                                            quarterOfYear(new Date(deliverablesMap[row].dueDate)) +
                                                                                            ', ' +
                                                                                            new Date(deliverablesMap[row].dueDate).getFullYear()
                                                                                        ) : (
                                                                                            'None'
                                                                                        )}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {deliverablesMap[row].isComplete ? 'Complete' : 'In Progress'}
                                                                                    </TableCell>*/}
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        ) : (
                                                            <Typography variant='subtitle2'>
                                                                No deliverables to display
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}
                <Grid item xs spacing={1} direction='column' sx={{ p: 4, ml: 2, mt: 4 }}>
                    <Grid item sx={{ mt: -1, mb: 1.2, ml: 2 }}>
                        <Typography variant='h2'>Linked data</Typography>
                    </Grid>
                    <Grid container item alignItems='center' justifyContent='space-between' sx={{ ml: -1 }} xs>
                        <Grid item xs={6} sx={{ pl: 1, pt: 1, pb: 1, ml: 2 }}>
                            <Tabs
                                value={value}
                                onChange={handleTabChange}
                                indicatorColor='primary'
                                textColor='primary'
                                aria-label='full width tabs example'>
                                <Tab
                                    label={store.getState().settings?.displayNames?.valueMessages || 'Loading'}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    label={store.getState().settings?.displayNames?.evidence || 'Loading'}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </Grid>
                        <Grid container item xs='auto' sx={{ pt: 1.8, pr: 2 }}>
                            {!snapshotMode && !userReadonly && (
                                <CreateLink
                                    sx={{ mt: 2 }}
                                    srcPage={'activity'}
                                    id={linkButtonTabIndexMapping[value]}
                                    listItems={value === 0 ? linkedValueMessages : linkedEvidence}
                                    update={value === 0 ? addLinkedValueMessages : addLinkedEvidence}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item xs justifyContent='space-between' sx={{ pt: 1, mr: 0 }}>
                        <TabPanel
                            value={value}
                            index={0}
                            dir={theme.direction}
                            style={{ marginRight: 8, width: '100%' }}>
                            {linkedValueMessages && linkedValueMessages.length ? (
                                linkedValueMessages.map((vm, i) => (
                                    <Link
                                        component={RouterLink}
                                        underline='none'
                                        color='secondary'
                                        to={{
                                            pathname: '/value-messages',
                                            autoOpenData: {
                                                isAutoOpenPanel: true,
                                                autoOpenData: vm,
                                                previousDataStack: addToStack(),
                                            },
                                        }}>
                                        <Grid
                                            container
                                            item
                                            wrap='nowrap'
                                            xs
                                            alignItems='flex-start'
                                            sx={{
                                                backgroundColor: isDarkMode ? 'rgba(0, 0, 0, .3)' : '#F6F6F7',
                                                mb: 2,
                                                pb: 2,
                                                p: 1,
                                                borderRadius: '16px',
                                            }}>
                                            <Grid item xs={0} sx={{ ml: 2, mt: 1, mr: 1 }}>
                                                <Typography variant='body1'>{i + 1}.</Typography>
                                            </Grid>
                                            <Grid container item direction='column' sx={{ mt: 1 }}>
                                                <Grid item xs sx={{ pr: 1 }}>
                                                    <Typography variant='body1'>{vm.name}</Typography>
                                                </Grid>
                                                <Grid item xs zeroMinWidth sx={{ mt: 1, mb: 1 }}>
                                                    <Chip
                                                        sx={{ backgroundColor: isDarkMode ? 'black' : '#EBF1FD' }}
                                                        label={
                                                            <Typography variant='chip'>
                                                                {_.isEmpty(settings)
                                                                    ? ''
                                                                    : settings['vmCategoryDisplayNames'][vm.category]}
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={0} sx={{ mt: 2, ml: 2, mr: 2 }}>
                                                <ArrowForwardIosIcon fontSize='small' color='primary' />
                                            </Grid>
                                        </Grid>
                                    </Link>
                                ))
                            ) : (
                                <Alert
                                    icon={<LinkOffIcon fontSize='inherit' />}
                                    severity='info'
                                    sx={{ borderRadius: '9px', mt: -1.2 }}>
                                    {`No Linked ${store.getState().settings?.displayNames?.valueMessages || 'Loading'}`}
                                </Alert>
                            )}
                        </TabPanel>
                        <TabPanel
                            value={value}
                            index={1}
                            dir={theme.direction}
                            style={{ marginLeft: 8, marginTop: 8, paddingRight: 9, width: '100%' }}>
                            {linkedEvidence && linkedEvidence.length ? (
                                linkedEvidence.map((ev, i) => (
                                    <>
                                        <Grid
                                            container
                                            item
                                            wrap='noWrap'
                                            spacing={1}
                                            alignItems='center'
                                            justifyContent='space-between'
                                            sx={{
                                                backgroundColor: isDarkMode
                                                    ? 'rgba(0, 0, 0, .3)'
                                                    : 'rgba(0, 0, 0, .03)',
                                                mb: 3,
                                                pb: 2,
                                                p: 1,
                                                borderRadius: '16px',
                                            }}>
                                            {activity.primaryEvidence &&
                                            activity.primaryEvidence.includes(ev._id.toString()) ? (
                                                // eslint-disable-next-line
                                                <Grid item sx={{ p: 1 }}>
                                                    <a href='javascript:void(0)'>
                                                        <img
                                                            src={icon_pin}
                                                            alt='Icon Pin'
                                                            style={{ width: '1.5vw', objectFit: 'contain' }}
                                                            onClick={() =>
                                                                !snapshotMode &&
                                                                !userReadonly &&
                                                                removePrimaryEvidence(ev._id)
                                                            }
                                                        />
                                                    </a>
                                                </Grid>
                                            ) : (
                                                // eslint-disable-next-line
                                                <Grid item sx={{ p: 1, mt: 1 }}>
                                                    <a href='javascript:void(0)'>
                                                        <img
                                                            src={icon_pin_hole}
                                                            alt='Icon Pin Hole'
                                                            style={{
                                                                width: '1.6vw',
                                                                padding: '.4vw',
                                                                objectFit: 'contain',
                                                            }}
                                                            onClick={() =>
                                                                !snapshotMode &&
                                                                !userReadonly &&
                                                                addPrimaryEvidence(ev._id)
                                                            }
                                                        />
                                                    </a>
                                                </Grid>
                                            )}
                                            <Grid
                                                container
                                                item
                                                wrap='nowrap'
                                                alignItems='flex-start'
                                                justifyContent='space-between'
                                                sx={{ pb: 1 }}>
                                                <Grid item xs={0} sx={{ mr: 1, pt: 0.5 }}>
                                                    <Typography variant='body1'>{i + 1}.</Typography>
                                                </Grid>
                                                <Grid item xs zeroMinWidth sx={{ pb: 0.5, pt: 0.5 }}>
                                                    <Typography variant='body1'>{ev.name}</Typography>
                                                </Grid>
                                                <Link
                                                    component={RouterLink}
                                                    underline='none'
                                                    color='secondary'
                                                    to={{
                                                        pathname: '/evidence',
                                                        autoOpenData: {
                                                            isAutoOpenPanel: true,
                                                            autoOpenData: ev,
                                                            previousDataStack: addToStack(),
                                                        },
                                                    }}>
                                                    <Grid item xs={1} sx={{ mr: 0.9 }}>
                                                        <IconButton
                                                            color='primary'
                                                            aria-label='upload picture'
                                                            component='label'>
                                                            <ArrowForwardIosIcon fontSize='small' color='primary' />
                                                        </IconButton>
                                                    </Grid>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </>
                                ))
                            ) : (
                                <Alert
                                    icon={<LinkOffIcon fontSize='inherit' />}
                                    severity='info'
                                    sx={{ borderRadius: '9px', mt: -2.2, ml: -1 }}>
                                    {`No Linked ${store.getState().settings?.displayNames?.evidence || 'Loading'}`}
                                </Alert>
                            )}
                        </TabPanel>
                    </Grid>
                </Grid>
                <Dialog
                    open={openModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenModal(false)}
                    aria-labelledby='alert-dialog-slide-title'
                    aria-describedby='alert-dialog-slide-description'>
                    <DialogTitle id='alert-dialog-slide-title'>{'Maximum Primary Links Exceeded'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-slide-description'>
                            Only {MAX_PRIMARY_EVIDENCE} Primary Linkages are allowed. Please remove other Primary Links
                            before continuing.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenModal(false)}>OK</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openErrorModal}
                    onClose={handleCloseErrorModal}
                    aria-labelledby='alert-dialog-title2'
                    aria-describedby='alert-dialog-description2'>
                    <DialogTitle id='alert-dialog-slide-title'>Error</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-slide-description'>
                            Please select an End Date that occurs after the Start Date
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseErrorModal}>OK</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDependencyModal}
                    onClose={() => setOpenDependencyModal(false)}
                    aria-labelledby='alert-dialog-title3'
                    aria-describedby='alert-dialog-description3'>
                    <DialogTitle id='alert-dialog-slide-title'>Dependencies</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-slide-description'>
                            Select {activitiesDisplayName} Dependent on "{activityTitle}"
                        </DialogContentText>
                        <Fade in={isLoaded}>
                            <FormControl component='fieldset' className={classes.formControl}>
                                {allOtherActivities &&
                                    allOtherActivities.length &&
                                    allOtherActivities.map((act, i) => (
                                        <div key={'actDV' + i}>
                                            <FormControlLabel
                                                control={
                                                    <BlueCheckBox
                                                        val={act._id}
                                                        color='primary'
                                                        defaultChecked={checkedActivitiesMap[act._id]}
                                                    />
                                                }
                                                label={act.title}
                                            />
                                        </div>
                                    ))}
                            </FormControl>
                        </Fade>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDependencyModal(false)} variant='outlined'>
                            Cancel
                        </Button>
                        <Button onClick={updateDependencies} variant='outlined'>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDeliverableDeleteModal}
                    onClose={() => setOpenDeliverableDeleteModal(false)}
                    aria-labelledby='alert-dialog-title4'
                    aria-describedby='alert-dialog-description4'>
                    <DialogTitle id='alert-dialog-slide-title2'>Delete Deliverable</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-slide-description'>
                            Are you sure you want to delete this deliverable?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDeliverableDeleteModal(false)} variant='contained-sm-button'>
                            Cancel
                        </Button>
                        <Button onClick={() => deleteDeliverable(deleteDeliverableId)} variant='outlined-sm-button'>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Fade>
    )
}

const ActivityDetailedView = connect(null, mapDispatchToProps)(ConnectedActivityDetailedView)

export default ActivityDetailedView
