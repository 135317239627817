import React, { useEffect, useState } from 'react'
import './gap.scss'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Typography,
    Button,
    List,
    ListItem,
    IconButton,
} from '@mui/material'
import { StyledTableRow } from '../Support/CustomElements'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import SvgIcon from '@mui/material/SvgIcon'
import Chip from '@mui/material/Chip'
import { ArrowDropDown, ArrowDropUp, Edit } from '@mui/icons-material'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { ReactComponent as AddIcon } from '../../assets/images/add.svg'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Paper from '@mui/material/Paper'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'
import Checkbox from '@mui/material/Checkbox'
import { useAuth0 } from '../../contexts/auth0-context'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import store from '../../redux/store'
import { useSelector } from 'react-redux'
import SwipeableViews from 'react-swipeable-views'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import * as _ from 'underscore'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Alert from '@mui/material/Alert'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import { quarterOfYear } from '../../utilities/helpers'

DialogTitle.propTypes = {
    children: PropTypes.node,
}

function TabPanel(props) {
    const { children, tabValue, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={tabValue !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {tabValue === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    tabValue: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function EvidenceGapsRevamp(props) {
    const buttonToCategoryMapping = {
        0: 'Unmet Need',
        1: 'Clinical',
        2: 'Economic',
        3: 'Humanistic',
    }
    let filterCategory = buttonToCategoryMapping[useSelector((state) => state.valueMessageFilterMapping)]

    const { isAuthenticated, getTokenSilently } = useAuth0()

    const [openGapModal, setOpenGapModal] = useState(false)
    const [gaps, setGaps] = useState([])
    const [gapActivities, setGapActivities] = useState()
    const [anchorEl, setAnchorEl] = useState(null)
    const [editGap, setEditGap] = useState(false)
    const [selectedGapRow, setSelectedGapRow] = useState(null)
    const [selectedGap, setSelectedGap] = useState(null)
    const [editMode, setEditMode] = useState(false)
    const [newActivity, setNewActivity] = useState('')
    const [selectedRowId, setSelectedRowId] = useState(null)
    const [selectedGapRowId, setSelectedGapRowId] = useState(null)
    const [selectedGapId, setSelectedGapId] = useState(null)
    const [fullDataSet, setFullDataSet] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [activitiesMap, setActivitiesMap] = useState({})
    const [gapMap, setGapMap] = useState({})
    const [gapExtendedFabMap, setGapExtendedFabMap] = useState({})
    const [combinedData, setCombinedData] = useState([])
    const [openVm, setOpenVm] = useState('')
    const [vmMap, setVMMap] = useState({})
    const [actMap, setActMap] = useState({})
    const [evMap, setEvMap] = useState({})
    const [tab, setTab] = useState(0)
    const [evList, setEvList] = useState([])
    const [actList, setActList] = useState([])
    const [openVMIndex, setOpenVMIndex] = useState(null)
    const [newGapText, setNewGapText] = useState('')
    const [editGapText, setEditGapText] = useState('')
    const [newEditGapText, setNewEditGapText] = useState('')
    const [gapStatus, setGapStatus] = useState(false)
    const [newGapStatus, setNewGapStatus] = useState(false)
    const [openGapId, setOpenGapId] = useState('')
    const [gapIdMap, setGapIdMap] = useState({})

    const [oldGapActivityMap, setOldGapActivityMap] = useState({})
    const [newGapActivityMap, setNewGapActivityMap] = useState({})
    // Add a new gap on main screen

    useEffect(() => {
        if (!isAuthenticated) {
            return
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    async function getGaps() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let gaps = await callAPI(
            endpoints.getEvidenceGapsByStrategyId.url + store.getState().strategy._id,
            endpoints.getEvidenceGapsByStrategyId.method,
            token
        )

        if (gaps.success) {
            let tempFabMap = {}
            let tempGaps = {}
            let tempGapMap = {}
            let realGapMap = {}
            let tempGapIdMap = {}
            for (let gap of gaps.data) {
                tempFabMap[gap._id] = false
                tempGapMap[gap._id] = gap
                tempGapIdMap[gap._id] = gap
                if (tempGaps[gap.valueMessageId]) {
                    tempGaps[gap.valueMessageId].push(gap)
                    let sorted = tempGaps[gap.valueMessageId].sort((x, y) => x.order - y.order)
                    tempGaps[gap.valueMessageId] = sorted
                    tempGapMap = sorted
                } else {
                    tempGaps[gap.valueMessageId] = [gap]
                    tempGapMap = [gap]
                }

                if (gap.valueMessageId in realGapMap) {
                    let newArray = Array.from(realGapMap[gap.valueMessageId])
                    newArray.push(gap)
                    let sorted = newArray.sort((x, y) => x.order - y.order)
                    realGapMap[gap.valueMessageId] = sorted
                } else {
                    realGapMap[gap.valueMessageId] = [gap]
                }
            }
            setGapIdMap(tempGapIdMap)
            setGapMap(realGapMap)
            setGaps(tempGaps)
            setGapExtendedFabMap(tempFabMap)
            return realGapMap
        }
        return {}
    }

    async function getVMData() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingVMs = await callAPI(
            endpoints.getValueMessagesForTable.url + store.getState().strategy._id,
            endpoints.getValueMessagesForTable.method,
            token
        )

        if (incomingVMs.success) {
            let tempVMMap = {}
            for (let vm of incomingVMs.data) {
                tempVMMap[vm._id] = vm
            }
            let vmsToSet = incomingVMs.data.length > 0 ? incomingVMs.data.reverse() : []
            if (store.getState().selectedMarket !== 'Global') {
                vmsToSet = vmsToSet.filter((vm) => vm.localMarkets.includes(store.getState().selectedMarket))
            } else if (store.getState().selectedMarket === 'Global') {
                vmsToSet = vmsToSet.filter((vm) => !vm.isCountrySpecific)
            }
            setFullDataSet(vmsToSet)
            setVMMap(tempVMMap)
            return vmsToSet
        }
        return []
    }

    async function getActivities() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let activities = await callAPI(
            endpoints.getActivitiesForTable.url + store.getState().strategy._id,
            endpoints.getActivitiesForTable.method,
            token
        )

        if (activities.success) {
            let tempMap = {}
            for (let act of activities.data) {
                tempMap[act._id] = act
            }
            setActMap(tempMap)
            setActList(activities.data)
            return tempMap
        }
        return {}
    }

    async function getEvidence() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let evidence = await callAPI(
            endpoints.getEvidenceForTable.url + store.getState().strategy._id,
            endpoints.getEvidenceForTable.method,
            token
        )

        if (evidence.success) {
            let tempMap = {}
            for (let vm of evidence.data) {
                tempMap[vm._id] = vm
            }
            setEvMap(tempMap)
            setEvList(evidence.data)
            return tempMap
        }
        return {}
    }

    async function getData() {
        let tempVMData = await getVMData()
        let tempActData = await getActivities()
        let tempEvData = await getEvidence()
        let tempGapData = await getGaps()

        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingActivities = await callAPI(
            endpoints.getActivitiesForTable.url + store.getState().strategy._id,
            endpoints.getActivitiesForTable.method,
            token
        )
        if (incomingActivities.success) {
            setGapActivities(incomingActivities.data)
            let obj = {}
            for (let act of incomingActivities.data) {
                obj[act._id] = act
            }
            setActivitiesMap(obj)
        }

        let arr = []
        let j = 1
        tempVMData.forEach((vm) => {
            if (filterCategory === 'All' || vm.category === filterCategory) {
                // const newGaps = gapMap && gapMap[vm._id] ? gapMap[vm._id] : []
                let dataRow = createData(
                    j,
                    vm.name,
                    vm._id,
                    vm._id in tempGapData
                        ? tempGapData[vm._id].map((gap, i) => {
                              return {
                                  id: i,
                                  title: gap.text,
                                  activities: gap.linkedActivities,
                                  status: gap.isOpen,
                                  gapId: gap._id,
                              }
                          })
                        : []
                )
                j += 1
                arr.push(dataRow)
            }
        })
        setCombinedData(arr)

        setGaps(arr)

        // loaded animation?
        setIsLoaded(true)
    }

    useEffect(() => {
        if (filterCategory === 'All') {
            filterAll()
            return
        }
        filter(filterCategory)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterCategory])

    const filterAll = () => {
        createDataRows(fullDataSet)
    }

    const filter = (categoryToFilter) => {
        createDataRows(fullDataSet.filter((vm) => vm.category === categoryToFilter).reverse())
    }

    function createData(id, valueMessage, valueMessageId, gaps) {
        return {
            id,
            valueMessage,
            valueMessageId,
            gaps,
        }
    }

    async function createDataRows(data) {
        let arr = []
        let j = 1

        data.forEach((vm) => {
            let dataRow = createData(
                j,
                vm.name,
                vm._id,
                vm._id in gapMap
                    ? gapMap[vm._id].map((gap, i) => {
                          return {
                              id: i,
                              title: gap.text,
                              activities: gap.linkedActivities,
                              status: gap.isOpen,
                              gapId: gap._id,
                          }
                      })
                    : []
            )
            j += 1
            arr.push(dataRow)
        })
        setCombinedData(arr)
    }

    const handleAddGap = async () => {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(endpoints.createEvidenceGap.url, endpoints.createEvidenceGap.method, token, {
            text: newGapText,
            order: 0,
            isOpen: false,
            valueMessageId: openVm,
            linkedActivities: [],
            strategyId: store.getState().strategy._id,
            organizationId: store.getState().strategy.organizationId,
        })

        getData()
        setNewGapText('')
    }

    // Enter Workshop view
    const handleOpenModal = (vmId, index) => {
        setOpenVm(vmId)
        setOpenVMIndex(index - 1)
        setOpenGapModal(true)
        setEditMode(false)
    }

    // Opens/closes edit menu
    const isMenuOpen = Boolean(anchorEl)

    const handleEditClick = (event, rowId, gapId) => {
        setAnchorEl(event.currentTarget)
        setSelectedGapRowId(rowId)
        setSelectedGapId(gapId)
    }

    const handleEditClose = () => {
        setSelectedGap(null)
        setSelectedGapId(null)
        setEditMode(false)
        setAnchorEl(null)
    }

    const handleDeleteGap = async (x) => {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(endpoints.deleteEvidenceGap.url + x, endpoints.deleteEvidenceGap.method, token)
        getData()
    }

    async function saveNewActivity() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingSettings = await callAPI(
            endpoints.getSettingsByOrganizationId.url + store.getState().strategy.organizationId,
            endpoints.getSettingsByOrganizationId.method,
            token
        )
        if (!incomingSettings.success) {
            return
        }
        let incomingIntendedUses = incomingSettings.data.activityIntendedUses
        let intendedUseObj = {}
        for (let i = 0; i < incomingIntendedUses.length; i++) {
            intendedUseObj[incomingIntendedUses[i]] = false
        }

        await callAPI(endpoints.createActivity.url, endpoints.createActivity.method, token, {
            studyNumber: '',
            title: newActivity,
            description: '',
            dataSource: '',
            budget: 0,
            category: '',
            intendedUse: intendedUseObj,
            function: '',
            funded: false,
            startDate: null,
            endDate: null,
            isComplete: false,
            isInitiated: false,
            isPlanned: false,
            isOnHold: false,
            isCancelled: false,
            strategyId: store.getState().strategy._id,
            organizationId: store.getState().strategy.organizationId,
            linkedEvidence: [],
            linkedValueMessages: [],
            primaryEvidence: [],
            publication: {
                isPublication: false,
                Journal: '',
                Congress: '',
                Manuscript: '',
            },
            vendor: false,
            vendorDetails: '',
            activityDependencies: [],
            status: ''
        })
        getData()
    }

    function getCustomDate(startDate) {
        if (!startDate) {
            return '--'
        }
        return `${new Date(startDate).getFullYear()}, Q${quarterOfYear(new Date(startDate))}`
    }

    // Save Workshop changes
    const handleSave = () => {
        // TODO: Update data and close modal
        handleClose()
    }

    // Close & exit WS view
    const handleClose = () => {
        setOpenGapModal(false)
        setSelectedGapRow(null)
        setSelectedGap(null)
        setNewActivity('')
        setEditMode(false)
    }

    const handleCancel = () => {
        setEditGap(false)
    }

    // Toggle between Ev & Act in WS view

    const handleTabChange = (event, newTabValue) => {
        setTab(newTabValue)
    }

    async function handleGapSave() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(endpoints.updateEvidenceGap.url + openGapId, endpoints.updateEvidenceGap.method, token, {
            text: newEditGapText,
            order: gapIdMap[openGapId].order,
            isOpen: newGapStatus,
            valueMessageId: gapIdMap[openGapId].valueMessageId,
            linkedActivities: Object.keys(newGapActivityMap).filter((actId) => newGapActivityMap[actId]),
            strategyId: gapIdMap[openGapId].strategyId,
            organizationId: gapIdMap[openGapId].organizationId,
        })
        getData()
        setEditGap(false)
    }

    // Add/Remove Act
    const handleAddActivity = () => {
        const newActivities = [...selectedGapRow.activities, newActivity]
        setSelectedGapRow({ ...selectedGapRow, activities: newActivities })
        setNewActivity('')
    }

    function swapIndexes(list, index1, index2) {
        let tempList = Array.from(list)
        if (index1 >= 0 && index1 < list.length && index2 >= 0 && index2 < list.length) {
            const temp = tempList[index1]
            tempList[index1] = list[index2]
            tempList[index2] = temp
        }
        return tempList
    }

    async function handleArrowReorder(vmId, index1, index2) {
        let swapped = swapIndexes(Array.from(gapMap[vmId]), index1, index2)
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        for (let i = 0; i < swapped.length; i++) {
            let result = await callAPI(
                endpoints.updateEvidenceGap.url + swapped[i]._id,
                endpoints.updateEvidenceGap.method,
                token,
                {
                    text: swapped[i].text,
                    order: i,
                    isOpen: swapped[i].isOpen,
                    valueMessageId: swapped[i].valueMessageId,
                    linkedActivities: swapped[i].linkedActivities,
                    strategyId: swapped[i].strategyId,
                    organizationId: swapped[i].organizationId,
                }
            )
        }
        getData()
    }

    // Switch to the next VM
    const handleNextVM = () => {
        setOpenGapModal(false)
    }

    const renderTableGapRows = (row) => {
        const tableGapRows = []

        if (!row.gaps.length) {
            return (
                <StyledTableRow key={`${row.id}`}>
                    <TableCell>
                        <Typography variant="body1">{row.id}.</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body1">{row.valueMessage}</Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell>
                        <IconButton color="primary" onClick={() => handleOpenModal(row.valueMessageId, row.id)}>
                            <Edit />
                        </IconButton>
                    </TableCell>
                </StyledTableRow>
            )
        }

        row.gaps.forEach((gap, gapIndex) => {
            tableGapRows.push(
                <StyledTableRow key={`${row.id}-${gap.id}`}>
                    {gapIndex === 0 && (
                        <>
                            <TableCell rowSpan={row.gaps.length}>
                                <Typography variant="body1">{row.id}</Typography>
                            </TableCell>
                            <TableCell rowSpan={row.gaps.length}>
                                <Typography variant="body1">{row.valueMessage}</Typography>
                            </TableCell>
                        </>
                    )}
                    <TableCell>
                        <Typography variant="body1">{gapIndex + 1}.</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body1">{gap.title}</Typography>
                    </TableCell>
                    <TableCell>
                        <List>
                            {gap.activities.map((activity, index) => (
                                <ListItem key={index}>
                                    <Typography variant="body1">
                                        {activity in activitiesMap ? activitiesMap[activity].title : ''}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </TableCell>
                    <TableCell>
                        <span className={gap.status ? 'knownDotDisplay' : 'aspirationalDotDisplay'} />
                    </TableCell>
                    {gapIndex === 0 && (
                        <TableCell>
                            <IconButton color="primary" onClick={() => handleOpenModal(row.valueMessageId, row.id)}>
                                <Edit />
                            </IconButton>
                        </TableCell>
                    )}
                </StyledTableRow>
            )
        })
        return tableGapRows
    }

    const renderWorkshopGapRows = (row) => {
        const gapRows = []
        if (row.valueMessageId === openVm) {
            row.gaps
                .sort((gap1, gap2) => gap1.order - gap2.order)
                .forEach((gap, gapIndex) => {
                    gapRows.push(
                        <StyledTableRow key={`${row.id}-${gap.id}`}>
                            <TableCell sx={{ pl: 0 }}>
                                <IconButton disabled={gapIndex === 0}>
                                    {gapIndex !== 0 ? (
                                        <ArrowDropUp
                                            color="secondary"
                                            onClick={() => {
                                                handleArrowReorder(row.valueMessageId, gapIndex, gapIndex - 1)
                                            }}
                                        />
                                    ) : (
                                        <ArrowDropUp color="disabled" />
                                    )}
                                </IconButton>
                                <IconButton disabled={gapIndex === row.gaps.length - 1}>
                                    {gapIndex !== row.gaps.length - 1 ? (
                                        <ArrowDropDown
                                            color="secondary"
                                            onClick={() => {
                                                handleArrowReorder(row.valueMessageId, gapIndex, gapIndex + 1)
                                            }}
                                        />
                                    ) : (
                                        <ArrowDropDown color="disabled" />
                                    )}
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">{gapIndex + 1}.</Typography>
                            </TableCell>
                            <TableCell>
                                <Grid container justifyContent="space-between" direction="column">
                                    <Grid item>
                                        <Typography variant="body1">{gap.title}</Typography>
                                    </Grid>
                                    <Grid item>{gap.status}</Grid>
                                </Grid>
                            </TableCell>
                            <TableCell>
                                <Grid container item wrap="nowrap" spacing={1}>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                handleDeleteGap(gap.gapId)
                                            }}>
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                setEditGap(true)
                                                setEditGapText(gap.title)
                                                setNewEditGapText(gap.title)
                                                setGapStatus(gap.status)
                                                setNewGapStatus(gap.status)
                                                setOpenGapId(gap.gapId)

                                                let temp = {}
                                                for (let act of Object.keys(activitiesMap)) {
                                                    temp[act] = gapIdMap[gap.gapId].linkedActivities.includes(act)
                                                }
                                                setNewGapActivityMap(temp)
                                                setOldGapActivityMap(temp)
                                            }}>
                                            Edit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell align="center">
                                <span className={gap.status ? 'knownDotDisplay' : 'aspirationalDotDisplay'} />
                            </TableCell>
                            <TableCell>
                                {gap.activities.map((activity, index) => (
                                    <Accordion key={index}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
                                            {activitiesMap[activity] && activitiesMap[activity].title}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={1}>
                                                <Grid container item direction="column" alignItems="center" xs={6}>
                                                    <Grid item>
                                                        <Typography variant="button" color="primary">
                                                            {activitiesMap[activity] &&
                                                            activitiesMap[activity].deliverables &&
                                                            activitiesMap[activity].deliverables.length
                                                                ? activitiesMap[activity].deliverables.length
                                                                : 0}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="subtitle2">DELIVERABLES</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item direction="column" alignItems="center" xs={6}>
                                                    <Grid item>
                                                        <Typography variant="button" color="primary">
                                                            {activitiesMap[activity] &&
                                                            activitiesMap[activity].activityDependencies &&
                                                            activitiesMap[activity].activityDependencies.length
                                                                ? activitiesMap[activity].activityDependencies.length
                                                                : 0}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="subtitle2">DEPENDENCIES</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item direction="column" alignItems="center" xs={6}>
                                                    <Grid item>
                                                        <Typography variant="button" color="primary">
                                                            {activitiesMap[activity] &&
                                                                getCustomDate(activitiesMap[activity].startDate)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="subtitle2">Start Date</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item direction="column" alignItems="center" xs={6}>
                                                    <Grid item>
                                                        <Typography variant="button" color="primary">
                                                            {activitiesMap[activity] &&
                                                                getCustomDate(activitiesMap[activity].endDate)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="subtitle2">END DATE</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </TableCell>
                        </StyledTableRow>
                    )
                })
        }
        return gapRows
    }

    const renderWorkshopActRows = (row, selectedGapRowId, selectedGapId, onSelectAllClick, numSelected, rowCount) => {
        // if (row.id !== selectedGapRowId) {
        //     return null
        // }
        // const selectedGap = row.gaps.find((gap) => gap.id === selectedGapId)
        // if (!selectedGap) {
        //     return null
        // }
        return Object.keys(activitiesMap).map((activity, i) => (
            <Accordion key={i} elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ backgroundColor: '#EDEDED' }}
                    aria-controls="panel1a-content">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={newGapActivityMap[activity]}
                        onClick={(e) => {
                            e.stopPropagation()
                            let temp = { ...newGapActivityMap }
                            temp[activity] = !temp[activity]
                            setNewGapActivityMap(temp)
                        }}
                    />
                    {activitiesMap[activity] && activitiesMap[activity].title}
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="row" justifyContent={'space-around'}>
                        <Grid item>
                            <Paper
                                sx={{
                                    padding: 0.5,
                                    textAlign: 'center',
                                    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1A2027' : '#fff'),
                                }}>
                                <Typography variant="body1">
                                    {activitiesMap[activity] &&
                                    activitiesMap[activity].deliverables &&
                                    activitiesMap[activity].deliverables.length
                                        ? activitiesMap[activity].deliverables.length
                                        : 0}
                                </Typography>
                                <Typography variant="subtitle2">DELIVERABLES</Typography>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper
                                sx={{
                                    padding: 0.5,
                                    textAlign: 'center',
                                    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1A2027' : '#fff'),
                                }}>
                                <Typography variant="body1">
                                    {activitiesMap[activity] &&
                                    activitiesMap[activity].activityDependencies &&
                                    activitiesMap[activity].activityDependencies.length
                                        ? activitiesMap[activity].activityDependencies.length
                                        : 0}
                                </Typography>
                                <Typography variant="subtitle2">DEPENDENCIES</Typography>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper
                                sx={{
                                    padding: 0.5,
                                    textAlign: 'center',
                                    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1A2027' : '#fff'),
                                }}>
                                <Typography variant="body1">
                                    {activitiesMap[activity] && getCustomDate(activitiesMap[activity].startDate)}
                                </Typography>
                                <Typography variant="subtitle2">Start Date</Typography>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper
                                sx={{
                                    padding: 0.5,
                                    textAlign: 'center',
                                    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1A2027' : '#fff'),
                                }}>
                                <Typography variant="body1">
                                    {activitiesMap[activity] && getCustomDate(activitiesMap[activity].endDate)}
                                </Typography>
                                <Typography variant="subtitle2">End Date</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        ))
    }

    return (
        <>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableCell />
                        <TableCell>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                Value Messages
                            </Typography>
                        </TableCell>
                        <TableCell />
                        <TableCell>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                Gap
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                Gap Activities
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                Status
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                Edit
                            </Typography>
                        </TableCell>
                    </TableHead>
                    <TableBody>{combinedData.map((row) => renderTableGapRows(row))}</TableBody>
                </Table>
            </TableContainer>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullWidth
                fullScreen
                open={openGapModal}>
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleNextVM}
                    sx={{ backgroundColor: '#360065', color: 'white' }}>
                    <Grid container fullWidth alignItems="center" justifyContent="space-between" wrap="nowrap">
                        <Grid container item wrap="nowrap" alignItems="center" spacing={2}>
                            <Grid item>
                                <IconButton edge="start" color="secondary" onClick={handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                    Evidence Gap
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs="auto">
                            {openVMIndex !== combinedData.length - 1 && (
                                <Button
                                    variant="contained"
                                    sx={{ borderRadius: 3 }}
                                    onClick={() => {
                                        setOpenVMIndex(openVMIndex + 1)
                                        setOpenVm(combinedData[openVMIndex + 1].valueMessageId)
                                    }}>
                                    Next Message
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={5}>
                        <Grid container item spacing={2} xs={4}>
                            <Grid container item spacing={3} direction="column">
                                <Grid container item spacing={2} alignItems="center">
                                    <Grid item>
                                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                            Value Message
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Chip
                                            label={
                                                openVm.length ? (vmMap[openVm].known ? 'Known' : 'Aspirational') : ''
                                            }
                                            sx={{
                                                backgroundColor: 'white',
                                                color: openVm.length
                                                    ? vmMap[openVm].known
                                                        ? 'green'
                                                        : 'orange'
                                                    : 'white',
                                                fontWeight: '600',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">
                                        {openVm.length ? vmMap[openVm].name : 'No VM'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Paper elevation={1} sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs
                                            value={tab}
                                            onChange={handleTabChange}
                                            aria-label="basic tabs example"
                                            textColor="primary">
                                            <Tab label="Linked Evidence" {...a11yProps(0)} />
                                            <Tab label="Linked Activities" {...a11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                    <SwipeableViews axis={'x'} index={tab} onChangeIndex={(x) => setTab(x)}>
                                        <TabPanel tabValue={tab} index={0} dir={'ltr'}>
                                            <List>
                                                {openVm && openVm.length ? (
                                                    vmMap[openVm].linkedEvidence.map((ev, i) => (
                                                        <>
                                                            <Link
                                                                component={RouterLink}
                                                                underline="none"
                                                                color="secondary"
                                                                key={'linkedEv' + i}
                                                                to={{
                                                                    pathname: '/evidence-details',
                                                                    state: {
                                                                        evidenceId: ev,
                                                                    },
                                                                }}>
                                                                <Grid
                                                                    container
                                                                    fullWidth
                                                                    wrap="nowrap"
                                                                    spacing={1}
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center">
                                                                    <Grid item xs={1}>
                                                                        {i + 1}
                                                                    </Grid>
                                                                    <Grid item xs zeroMinWidth>
                                                                        <Typography variant="body1">
                                                                            {evMap[ev].name}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <ArrowForwardIosIcon fontSize="small" />
                                                                    </Grid>
                                                                </Grid>
                                                            </Link>
                                                            <Divider sx={{ m: 1 }} />
                                                        </>
                                                    ))
                                                ) : (
                                                    <Alert icon={<LinkOffIcon fontSize="inherit" />} severity="info" sx={{ borderRadius: '9px'}}>
                                                        {`No Linked ${
                                                            store.getState().settings?.displayNames?.evidence ||
                                                            'Loading'
                                                        }`}
                                                    </Alert>
                                                )}
                                            </List>
                                        </TabPanel>
                                        <TabPanel tabValue={tab} index={1} dir={'ltr'}>
                                            <List>
                                                {openVm && openVm.length ? (
                                                    vmMap[openVm].linkedActivities.map((act, i) => (
                                                        <>
                                                            <Link
                                                                component={RouterLink}
                                                                underline="none"
                                                                color="secondary"
                                                                key={'linkedAct' + i}
                                                                to={{
                                                                    pathname: '/activity-details',
                                                                    state: {
                                                                        activityId: act,
                                                                    },
                                                                }}>
                                                                <Grid
                                                                    container
                                                                    fullWidth
                                                                    wrap="nowrap"
                                                                    spacing={1}
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center">
                                                                    <Grid item xs={1}>
                                                                        {i + 1}
                                                                    </Grid>
                                                                    <Grid item xs zeroMinWidth>
                                                                        <Typography variant="body1">
                                                                            {actMap[act].title}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <ArrowForwardIosIcon fontSize="small" />
                                                                    </Grid>
                                                                </Grid>

                                                                {/*{_.isEmpty(store.getState().settings)*/}
                                                                {/*    ? ''*/}
                                                                {/*    : store*/}
                                                                {/*          .getState()*/}
                                                                {/*          .settings[*/}
                                                                {/*              'vmCategoryDisplayNames'*/}
                                                                {/*          ][ev.category]}*/}
                                                            </Link>
                                                            <Divider sx={{ m: 1 }} />
                                                        </>
                                                    ))
                                                ) : (
                                                    <Alert icon={<LinkOffIcon fontSize="inherit" />} severity="info" sx={{ borderRadius: '9px'}}>
                                                        {`No Linked ${
                                                            store.getState().settings?.displayNames?.evidence ||
                                                            'Loading'
                                                        }`}
                                                    </Alert>
                                                )}
                                            </List>
                                        </TabPanel>
                                    </SwipeableViews>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid container item xs>
                            {editGap ? (
                                <Grid container direction="column" spacing={2}>
                                    <Grid container item direction="row" justifyContent="space-between" xs>
                                        <Grid container item xs={7}>
                                            <Typography variant="caption">Linked Gap</Typography>
                                        </Grid>
                                        <Grid container item xs="auto" spacing={2}>
                                            <Grid item>
                                                <Button variant="outlined" onClick={handleCancel}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" onClick={handleGapSave}>
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction="column">
                                        <Box
                                            sx={{
                                                width: '100%',
                                                background: '#E0E1E4',
                                                p: 2,
                                                borderLeft: '3px solid #07cc9e',
                                            }}>
                                            <Grid item>
                                                <TextField
                                                    multiline
                                                    rows={4}
                                                    id="edit-gap-text"
                                                    label="Edit Gap Text"
                                                    placeholder={editGapText}
                                                    value={newEditGapText}
                                                    onFocus={(event) => {
                                                        event.target.setAttribute('autocomplete', 'off')
                                                    }}
                                                    onChange={(e) => setNewEditGapText(e.target.value)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid container item direction="row" alignItems="center">
                                                <Grid item>Open</Grid>
                                                <Grid item>
                                                    <Switch
                                                        color="success"
                                                        checked={newGapStatus}
                                                        onChange={() => setNewGapStatus(!newGapStatus)}
                                                    />
                                                </Grid>
                                                <Grid item>Closed</Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid containter item direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography variant="caption">Gap Activities</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid item xs>
                                                <TextField
                                                    fullWidth
                                                    label="Input New Activity"
                                                    multiline
                                                    value={newActivity}
                                                    onChange={(e) => setNewActivity(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault()
                                                            saveNewActivity()
                                                            setNewActivity('')
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item direction="column">
                                            {renderWorkshopActRows()}
                                            {/*{combinedData.map((row, i) =>*/}
                                            {/*    renderWorkshopActRows(*/}
                                            {/*        row,*/}
                                            {/*        selectedGapRowId,*/}
                                            {/*        selectedGapId,*/}
                                            {/*        i,*/}
                                            {/*        numSelected,*/}
                                            {/*        rowCount,*/}
                                            {/*        onRequestSort*/}
                                            {/*    )*/}
                                            {/*)}*/}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                    <Grid container>
                                        <Grid container item direction="column" spacing={3}>
                                            <Grid item>
                                                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                                    Gaps
                                                </Typography>
                                            </Grid>
                                            <Grid container item justifyContent="flex-end" spacing={1}>
                                                <TextField
                                                    id="new-gap-text"
                                                    label="New Gap Text"
                                                    placeholder={newGapText}
                                                    value={newGapText}
                                                    onFocus={(event) => {
                                                        event.target.setAttribute('autocomplete', 'off')
                                                    }}
                                                    onChange={(e) => setNewGapText(e.target.value)}
                                                    fullWidth
                                                />
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        sx={{ borderRadius: 3 }}
                                                        startIcon={<SvgIcon component={AddIcon} />}
                                                        onClick={() => {
                                                            handleAddGap()
                                                        }}>
                                                        New Gap
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <TableContainer>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableCell>
                                                            <Typography variant="caption">Order</Typography>
                                                        </TableCell>
                                                        <TableCell />
                                                        <TableCell>
                                                            <Typography variant="caption">Title</Typography>
                                                        </TableCell>
                                                        <TableCell />
                                                        <TableCell>
                                                            <Typography variant="caption">Status</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="caption">Gap Activities</Typography>
                                                        </TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {combinedData.map((row, i) => renderWorkshopGapRows(row))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
}
